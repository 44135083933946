import { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import { formatDateMonthYear, formatDate } from 'utils/dates';
import { Dropdown, InlineLoading } from 'carbon-components-react';
import { PropTypes } from 'prop-types';

const PERIODS = [
  {key: 'week', label: 'Weekly'},
  {key: 'month', label: 'Monthly'},
];

const datasets = [
  {key: 'total_open_count', label: 'Total Open', color: '#6a96bd', hidden: true},
  {key: 'submitted_gt30d_count', label: 'Submitted > 30 days', color: '#dd0000'},
  {key: 'under_review_gt90d_count', label: 'Under Review > 90 days', color: '#ff8400'},
  {key: 'decided_maybe_gt3y_count', label: 'Decided Maybe > 3yrs', color: '#ffbe00', hidden: true},
];

const datasets2 = [
  {key: 'new_count', label: 'New', color: '#6a96bd'},
  {key: 'delivered_count', label: 'Delivered', color: '#7fba61'},
];

const chartOptions = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      labels: {
        usePointStyle: true,
        padding: 20,
        boxWidth: 8,
      },
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 1,
      },
    },
    x: {
      type: 'category',
      ticks: {
        autoSkip: false,
      },
      grid: {
        offset: true,
      },
    },
  },
};

const TrendChartCard = ({ productRefs }) => {
  const [period, setPeriod] = useState(PERIODS[0]);
  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [chartData2, setChartData2] = useState(null);
  const [hasNoChartData, setHasNoChartData] = useState(false);

  const isCategoryFilters = productRefs && productRefs.some((ref) => ref.includes('.'));

  useEffect(() => {
    if (!productRefs) return;
    if (isCategoryFilters) return;

    const fetchIdeaTrends = async () => {
      setIsLoading(true);
      const params = { product_refs: productRefs.join(','), period: period.key };
      const { data } = await axios.get('/api/idea_trends', { params });

      if (data.length === 0) {
        setHasNoChartData(true);
        setChartData(null);
        setChartData2(null);
        return;
      }

      const chartLabels = data.map((d) => {
        if (period.key === 'month') {
          return formatDateMonthYear(d.period_last_day);
        }
        return formatDate(d.period_last_day);
      });

      const chartDatasets = datasets.map((dataset) => ({
        type: 'line',
        hidden: dataset.hidden,
        label: dataset.label,
        data: data.map((d) => d[dataset.key]),
        borderColor: dataset.color,
        backgroundColor: dataset.color,
        borderWidth: 1,
        tension: 0,
        segment: {
          borderDash: (ctx) => (ctx.p1DataIndex === data.length - 1 ? [3, 3] : []),
        },
      }));

      const chartDatasets2 = datasets2.map((dataset) => ({
        hidden: dataset.hidden,
        label: dataset.label,
        data: data.map((d) => d[dataset.key]),
        backgroundColor: dataset.color,
      }));

      setHasNoChartData(false);

      setChartData({
        labels: chartLabels,
        datasets: chartDatasets,
      });

      setChartData2({
        labels: chartLabels,
        datasets: chartDatasets2,
      });
      setIsLoading(false);
    };

    fetchIdeaTrends();
  }, [productRefs, isCategoryFilters, period]);

  let display = null;

  if (isCategoryFilters) {
    display = (
      <div className="no-data-for-chart">
        The trend chart is not available when product category filters are applied
      </div>
    );
  } else if (isLoading) {
    display = (
      <div className="no-data-for-chart">
        <InlineLoading />
        Loading Chart...
      </div>
    );
  } else if (chartData) {
    display = (
      <>
        <div style={{height: 300}}>
          <Bar options={chartOptions} data={chartData} />
        </div>
        <div style={{height: 300}}>
          <Bar options={chartOptions} data={chartData2} />
        </div>
      </>
    );
  } else if (hasNoChartData) {
    display = (
      <div className="no-data-for-chart">
        There are no ideas in the selected products
      </div>
    );
  }

  return (
    <div className="card trend-chart-card">
      <div className="card-header">
        <span>Backlog Trend</span>
        <Dropdown
          id="period-dropdown"
          label="Period"
          className="period-dropdown"
          type="inline"
          items={PERIODS}
          itemToString={(item) => item.label}
          selectedItem={period}
          size="sm"
          onChange={({selectedItem}) => setPeriod(selectedItem)}
        />
      </div>
      <div className="card-body">
        <div style={{height: 600}}>
          {display}
        </div>
      </div>
    </div>
  );
};

TrendChartCard.propTypes = {
  productRefs: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TrendChartCard;

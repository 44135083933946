import { useEffect, useState } from 'react';
import axios from 'axios';
import { InlineLoading, Dropdown } from 'carbon-components-react';
import { Link } from 'react-router-dom';
import NumberDelta from 'components/NumberDelta/NumberDelta';
import { formatDate } from '../../utils/dates';

const COMPARISON_PERIODS = [
  {key: 'week', label: 'Weekly Complete'},
  {key: 'month', label: 'Monthly Complete'},
  {key: 'quarter_to_date', label: 'Quarter to Date'},
];

function getComplianceClass(slaPercent) {
  const roundedPercent = Math.round(slaPercent * 1000) / 10;

  if (roundedPercent >= 99) {
    return 'sla-good';
  }
  if (roundedPercent >= 90) {
    return 'sla-warn';
  }
  return 'sla-bad';
}

const BusinessUnitSummaryPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [businessUnitRows, setBusinessUnitRows] = useState(null);

  const [comparisonPeriod, setComparisonPeriod] = useState(COMPARISON_PERIODS[0]);

  useEffect(() => {
    const fetchRows = async () => {
      setIsLoading(true);
      const params = { comparison_period: comparisonPeriod.key };
      const { data } = await axios.get('/api/business_unit_summary/summary', { params });
      setBusinessUnitRows(data);
      setIsLoading(false);
    };
    fetchRows();
  }, [comparisonPeriod]);

  return (
    <div className="bx--grid bx--grid--full-width business-unit-summary-page">
      <div className="bx--row">
        <div className="bx--col header">
          <h2>Business Unit Summary</h2>
          <div className="comparison-period">
            <strong>Comparison Period:</strong>
            <Dropdown
              id="comparison-period-dropdown"
              className="comparison-period-dropdown"
              type="inline"
              items={COMPARISON_PERIODS}
              itemToString={(item) => item.label}
              selectedItem={comparisonPeriod}
              size="sm"
              onChange={({selectedItem}) => setComparisonPeriod(selectedItem)}
            />
            {!isLoading && (
              <span>
                {'Comparing '}
                <strong>{formatDate(businessUnitRows[0].stats_from.date)}</strong>
                {' to '}
                <strong>{formatDate(businessUnitRows[0].stats_to.date)}</strong>
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <div className="card">
            <div className="card-body">
              {!isLoading ? (
                <table>
                  <thead>
                    <tr>
                      <th className="col-business-unit">Business Unit</th>
                      <th className="col-product-management-vp">Executive Stakeholder</th>
                      <th className="col-ideas-champion">Ideas Champion(s)</th>
                      <th className="col-sla-compliance">SLA Compliance<br />(30 and 90 day)</th>
                      <th className="col-draw-down-velocity">Overdue Ideas</th>
                    </tr>
                  </thead>
                  <tbody>
                    {businessUnitRows.map((businessUnit) => (
                      <tr key={businessUnit.utcode}>
                        <td className="col-business-unit">
                          <Link to={`/business-unit-summary/${businessUnit.utcode}`}>
                            {businessUnit.business_unit}
                          </Link>
                        </td>
                        <td className="col-product-management-vp">{businessUnit.product_management_vp}</td>
                        <td className="col-ideas-champion">{businessUnit.ideas_champions.join(', ')}</td>
                        <td className={`col-sla-compliance ${getComplianceClass(businessUnit.stats_to.sla_compliance_percent)}`}>
                          <div className="number-delta-container">
                            <div>{(Math.round(businessUnit.stats_to.sla_compliance_percent * 1000) / 10).toFixed(1)}%</div>
                            <div>
                              <NumberDelta
                                value={(Math.round((businessUnit.stats_to.sla_compliance_percent - businessUnit.stats_from.sla_compliance_percent) * 1000) / 10).toFixed(1)}
                                goodDirection="up"
                                suffix="%"
                              />
                            </div>
                          </div>
                        </td>
                        <td className="col-overdue">
                          <div className="number-delta-container">
                            <div>{businessUnit.stats_to.overdue}</div>
                            <div>
                              <NumberDelta
                                value={businessUnit.stats_to.overdue - businessUnit.stats_from.overdue}
                                goodDirection="down"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="logging-in">
                  <InlineLoading />
                  Loading Business Unit Summary...
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessUnitSummaryPage;

import { TextInput } from 'carbon-components-react';
import { useContext, useState} from 'react';
import UserContext from 'UserContext';

const MyWarningPeriods = () => {
  const {settings, updateSetting} = useContext(UserContext);
  const [submittedDays, setSubmittedDays] = useState(settings.sla_warning_days_submitted);
  const [underReviewDays, setUnderReviewDays] = useState(settings.sla_warning_days_under_review);
  const [submittedError, setSubmittedError] = useState(null);
  const [underReviewError, setUnderReviewError] = useState(null);

  const onSubmittedChange = (value) => {
    const newValue = parseInt(value, 10);
    setSubmittedDays(newValue || '');
    if (newValue) {
      if (newValue <= 30) {
        setSubmittedError(null);
        updateSetting('sla_warning_days_submitted', newValue);
      } else {
        setSubmittedError('Must be between 1 and 30 days');
      }
    }
  };

  const onUnderReviewChange = (value) => {
    const newValue = parseInt(value, 10);
    setUnderReviewDays(newValue || '');
    if (newValue) {
      if (newValue <= 90) {
        setUnderReviewError(null);
        updateSetting('sla_warning_days_under_review', newValue);
      } else {
        setUnderReviewError('Must be between 1 and 90 days');
      }
    }
  };

  const resetSubmitted = () => {
    if (submittedDays === '') {
      setSubmittedDays(settings.sla_warning_days_submitted);
    }
  };

  const resetUnderReview = () => {
    if (underReviewDays === '') {
      setUnderReviewDays(settings.sla_warning_days_under_review);
    }
  };

  return (
    <div className="card my-warning-periods">
      <div className="card-header">My Warning Periods</div>
      <div className="card-body">
        <div className="inline-field">
          <TextInput
            className="text-field"
            id="sla-warning-days-submitted"
            labelText="Warning period days for Submitted ideas"
            inline
            value={submittedDays}
            onChange={(e) => onSubmittedChange(e.target.value)}
            onBlur={resetSubmitted}
            autoComplete="off"
            maxLength="2"
          />
          {submittedError && <div className="error-message">{submittedError}</div>}
        </div>
        <div className="inline-field">
          <TextInput
            className="text-field"
            id="sla-warning-days-under-review"
            labelText="Warning period days for Under Review ideas"
            inline
            value={underReviewDays}
            onChange={(e) => onUnderReviewChange(e.target.value)}
            onBlur={resetUnderReview}
            autoComplete="off"
            maxLength="2"
          />
          {underReviewError && <div className="error-message">{underReviewError}</div>}
        </div>
      </div>
    </div>
  );
};

export default MyWarningPeriods;

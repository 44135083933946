import IdeasListing from './IdeasListing';

const RespondedIdeasTab = () => (
  <div className="bx--row">
    <div className="bx--col">
      <div className="card">
        <div className="card-body">
          <IdeasListing actionableType="response" />
        </div>
      </div>
    </div>
  </div>
);

export default RespondedIdeasTab;

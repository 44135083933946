/* eslint-disable jsx-a11y/control-has-associated-label */
import { useState, useEffect, useContext } from 'react';
import { Checkbox } from 'carbon-components-react';
import { Email20, LogoSlack20 } from '@carbon/icons-react';
import axios from 'axios';
import UserContext from 'UserContext';
import AddToSlackButton from './AddToSlackButton';
import TestSlackNotificationButton from './TestSlackNotificationButton';

const notificationProviders = [
  {key: 'email', icon: <Email20 /> },
  {key: 'slack', icon: <LogoSlack20 /> },
];

const notificationTypes = [
  {key: 'idea_overdue', description: 'Notify me when an idea in one of my products becomes overdue' },
  {key: 'idea_warning', description: 'Notify me when an idea in one of my products exceeds my warning period' },
  {key: 'idea_responded', description: 'Notify me when an idea in need of more information has been responded to' },
];

const MyNotifications = () => {
  const { settings, updateSetting } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [enabledNotifications, setEnabledNotifications] = useState([]);
  const [isEmailOverdueNag, setIsEmailOverdueNag] = useState(settings.email_overdue_nag);

  useEffect(() => {
    const fetchNotifications = async () => {
      const { data } = await axios.get('/api/me/notifications');
      setEnabledNotifications(data);
      setIsLoading(false);
    };

    fetchNotifications();
  }, []);

  useEffect(() => {
    if (!settings.is_slack_connected) {
      // Remove slack notifications if it's disabled
      setEnabledNotifications((prevEnabledNotifications) => (
        prevEnabledNotifications.filter((notification) => (
          notification.provider !== 'slack'
        ))
      ));
    }
  }, [settings.is_slack_connected]);

  const isNotificationEnabled = (notificationType, notificationProvider) => (
    enabledNotifications.some((notification) => (
      notification.type === notificationType && notification.provider === notificationProvider
    ))
  );

  const setNotification = (notificationType, notificationProvider, value) => {
    axios.patch('/api/me/notifications', {
      type: notificationType,
      provider: notificationProvider,
      enabled: value,
    });

    if (value) {
      // Add to list of enabled notifications
      if (!isNotificationEnabled(notificationType, notificationProvider)) {
        setEnabledNotifications((prevEnabledNotifications) => [
          ...prevEnabledNotifications,
          {type: notificationType, provider: notificationProvider},
        ]);
      }
    } else {
      // Remove from list of enabled notifications
      setEnabledNotifications((prevEnabledNotifications) => (
        prevEnabledNotifications.filter((notification) => (
          !(notification.type === notificationType && notification.provider === notificationProvider)
        ))
      ));
    }
  };
  const onOverdueNagChange = (value) => {
    setIsEmailOverdueNag(value);
    updateSetting('email_overdue_nag', value);
  };

  return (
    <div className="card my-notifications">
      <div className="card-header">My Notifications</div>
      <div className="card-body">
        <div className="slack-buttons-container">
          <AddToSlackButton isConnected={settings.is_slack_connected} />
          {settings.is_slack_connected && (
            <TestSlackNotificationButton />
          )}
        </div>

        <table>
          <thead>
            <tr>
              {notificationProviders.map((notificationProvider) => (
                <th key={notificationProvider.key}>{notificationProvider.icon}</th>
              ))}
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            {notificationTypes.map((notificationType) => (
              <tr key={notificationType.key}>
                {notificationProviders.map((notificationProvider) => (
                  <td className="col-notification-checkbox" key={`${notificationType.key}-${notificationProvider.key}`}>
                    <Checkbox
                      labelText=""
                      id={`notification-${notificationType.key}-${notificationProvider.key}`}
                      checked={isNotificationEnabled(notificationType.key, notificationProvider.key)}
                      onChange={(value) => setNotification(notificationType.key, notificationProvider.key, value)}
                      disabled={isLoading || (notificationProvider.key === 'slack' && !settings.is_slack_connected)}
                    />
                  </td>
                ))}
                <td>
                  {notificationType.description}
                </td>
                <td>
                  {notificationType.key === 'idea_overdue' && (
                    <Checkbox
                      labelText="Remind me of overdue ideas every day."
                      id="nagagram"
                      checked={isEmailOverdueNag}
                      onChange={onOverdueNagChange}
                      disabled={isLoading || !isNotificationEnabled('idea_overdue', 'email')}
                    />
                  )}

                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MyNotifications;

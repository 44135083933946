import PropTypes from 'prop-types';
import { ComboBox } from 'carbon-components-react';

const productToElement = (product) => (
  <div className="product-option">
    <span className="product-reference">{product.reference}</span>
    <span className="parent-name">{product.parent.name}:</span>
    <span className="product-name">{product.name}</span>
  </div>
);

const productToString = (product) => {
  if (!product || !product.id) {
    return '';
  }
  return `${product.parent.name}: ${product.name} (${product.reference})`;
};

const isProductMatch = (product, inputValue) => {
  const lowerInputValue = inputValue.toLowerCase();
  if (!product.id) return false;
  if (product.parentNameLower.includes(lowerInputValue)) return true;
  if (product.nameLower.includes(lowerInputValue)) return true;
  if (product.referenceLower.startsWith(lowerInputValue)) return true;
  return false;
};

const ProductDropdown = ({products, selectedProduct, onSelectedProductChange}) => (
  <ComboBox
    id="product-dropdown"
    className="product-dropdown"
    titleText="Add new Product"
    items={products}
    selectedItem={selectedProduct}
    itemToElement={(product) => productToElement(product)}
    itemToString={(product) => productToString(product)}
    shouldFilterItem={({item: product, inputValue}) => isProductMatch(product, inputValue)}
    placeholder="Find product..."
    onChange={({selectedItem}) => { onSelectedProductChange(selectedItem); }}
  />
);

const productPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  reference: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  parent: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  parentNameLower: PropTypes.string.isRequired,
  nameLower: PropTypes.string.isRequired,
  referenceLower: PropTypes.string.isRequired,
});

ProductDropdown.propTypes = {
  products: PropTypes.arrayOf(productPropType).isRequired,
  selectedProduct: productPropType,
  onSelectedProductChange: PropTypes.func.isRequired,
};

ProductDropdown.defaultProps = {
  selectedProduct: null,
};

export default ProductDropdown;

import { Help24, LogoSlack24, Blog24, Video24, Events24, Idea24 } from '@carbon/icons-react';

const SupportPage = () => (
  <div className="bx--grid bx--grid--full-width support-page">
    <div className="bx--row">
      <div className="bx--col header">
        <h2>Support &amp; Contact</h2>
      </div>
    </div>
    <div className="bx--row">
      <div className="bx--col-lg-12">
        <div className="card">
          <div className="card-body">
            <p>
              The Ideas Hub provides a streamlined experience with enhanced insights to empower Product Managers to more efficiently and effectively triage and act on Customer Ideas.
            </p>
            <ul className="link-list">
              <li><LogoSlack24 />Contact us on Slack, using the <a href="https://ibm-cloud.slack.com/archives/C02CEJ766KF">#aha-users</a> channel</li>
              <li><Help24 />Read the <a href="https://w3.ibm.com/w3publisher/rfe-request-for-enhancement/process/faq#IdeasHub">FAQs</a></li>
              <li><Blog24 />Read our blog post <a href="https://w3.ibm.com/w3publisher/rfe-request-for-enhancement/blog/486adaf0-5e80-11ec-883b-57e9dcc5aa6b">Introducing the Ideas Hub</a></li>
              <li><Video24 />Watch our <a href="https://ibm.box.com/s/ttm3ma9gqm63652xpxdmksephb8v8f9p">video demo</a></li>
              <li><Events24 />Meet the <a href="https://w3.ibm.com/w3publisher/rfe-request-for-enhancement/team">Customer Ideas Team</a></li>
              <li><Idea24 />Have an idea for us? Raise it in the <a href="https://transform.ideas.ibm.com">RFE Transformation Ideas Portal</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default SupportPage;

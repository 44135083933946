import { Link } from 'react-router-dom';

const ShellFooter = () => (
  <div className="shell-footer">
    <p>IBM Confidential - FOR INTERNAL USE ONLY</p>
    <ul>
      <li><Link to="/terms-and-conditions">Terms &amp; Conditions</Link></li>
      <li><a href="https://w3.ibm.com/w3publisher/w3-privacy-notice/">Privacy Policy</a></li>
      <li><a href="https://w3.ibm.com/w3publisher/trust-compliance/bcgs">Business Conduct Guidelines</a></li>
    </ul>
    <p>&copy; Copyright 2020 - {new Date().getFullYear()} IBM Corporation. All rights reserved.</p>
  </div>
);

export default ShellFooter;

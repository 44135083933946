import SpamIdeasListing from './SpamIdeasListing';

const SpamIdeasTab = () => (
  <div className="bx--row">
    <div className="bx--col">
      <div className="card">
        <div className="card-body">
          <SpamIdeasListing />
        </div>
      </div>
    </div>
  </div>
);

export default SpamIdeasTab;

import { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import { useQueryParamArray } from 'hooks/useQueryParam';
import { InlineLoading } from 'carbon-components-react';

const IdeaCategoriesCard = () => {
  const history = useHistory();

  const [chartData, setChartData] = useState(null);
  const [hasNoChartDataContent, setHasNoChartDataContent] = useState(false);
  const [productRefs] = useQueryParamArray('product_refs');

  const chartOptions = useMemo(() => {
    const productRefsStr = productRefs ? productRefs.join(',') : '';

    if (chartData !== null && chartData.labels.length === 0) {
      setHasNoChartDataContent(true);
    }

    return {
      indexAxis: 'y',
      scales: {
        x: {
          ticks: {precision: 0},
        },
        y: {
          ticks: {autoSkip: false},
        },
      },
      maintainAspectRatio: false,
      onHover: (event, activeElements) => {
        event.native.target.style.cursor = (activeElements.length > 0) ? 'pointer' : 'default';
      },
      onClick: (event, activeElements) => {
        if (activeElements.length > 0) {
          const category = chartData.labels[activeElements[0].index];

          const params = new URLSearchParams({
            product_refs: productRefsStr,
            category,
          });

          history.push({
            pathname: '/ideas/all',
            search: params.toString().replaceAll('%2C', ','),
          });
        }
      },
      plugins: {
        legend: { display: false },
      },
    };
  }, [productRefs, chartData, history]);

  useEffect(() => {
    if (!productRefs) return;

    const fetchCategories = async () => {
      const { data: categories } = await axios.get('/api/idea_categories', { params: {
        product_refs: productRefs.join(','),
      }});

      const chartLabels = categories.map((category) => category.category_name);
      const chartDatasets = [
        {
          label: 'Ideas',
          data: categories.map((c) => c.count),
          backgroundColor: '#407294',
        },
      ];

      setChartData({
        labels: chartLabels,
        datasets: chartDatasets,
      });
    };
    fetchCategories();
  }, [productRefs]);

  let display = null;

  if (chartData && !hasNoChartDataContent) {
    display = <Bar options={chartOptions} data={chartData} />;
  } else if (chartData && hasNoChartDataContent) {
    display = (
      <div className="no-data-for-chart">
        There are no categories for any ideas in the selected products
      </div>
    );
  } else {
    display = (
      <div className="no-data-for-chart">
        <InlineLoading />
        Loading Chart...
      </div>
    );
  }

  return (
    <div className="card">
      <div className="card-header">Open Idea Count by Category {chartData?.labels.length === 10 && '(Top 10)'}</div>
      <div className="card-body" style={{height: 300}}>
        {display}
      </div>
    </div>
  );
};

export default IdeaCategoriesCard;

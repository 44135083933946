import { useEffect, useState, useCallback, useContext } from 'react';
import { Button, InlineLoading, MultiSelect } from 'carbon-components-react';
import { Close16, Renew16, CheckmarkFilled16, Error16, Add16 } from '@carbon/icons-react';
import axios from 'axios';
import UserContext from 'UserContext';
import ProductDropdown from './ProductDropdown';
import ProductListSelection from './ProductListSelection';

const ALL_PRODUCT_CATEGORIES = 'ALL_CATEGORIES';

const MyProducts = () => {
  const {productLists, setProductLists, selectedProductList, selectProductList} = useContext(UserContext);
  const [allProducts, setAllProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const [isLoadingProductCategories, setIsLoadingProductCategories] = useState(false);
  const [productCategories, setProductCategories] = useState([]);
  const [selectedProductCategories, setSelectedProductCategories] = useState([]);

  const [isSyncing, setIsSyncing] = useState(false);
  const [syncStatus, setSyncStatus] = useState(null);
  const [errorText, setErrorText] = useState(null);

  const fetchAllProducts = useCallback(async (syncAccess) => {
    const params = { sync_access: syncAccess };
    const { data } = await axios.get('/api/products_with_access', { params });

    const newProducts = data.map((product) => ({
      ...product,
      parentNameLower: product.parent ? product.parent.name.toLowerCase() : '',
      nameLower: product.name.toLowerCase(),
      referenceLower: product.reference.toLowerCase(),
    }));
    setAllProducts(newProducts);
  }, [setAllProducts]);

  useEffect(() => {
    fetchAllProducts(false);
  }, [fetchAllProducts]);

  const addProduct = (newProduct, newCategories) => {
    setProductLists((oldProductLists) => oldProductLists.map((productList) => {
      if (productList.id !== selectedProductList.id) {
        return productList;
      }

      let { products } = productList;

      const categories = newCategories;

      if (categories.length === 0) {
        categories.push({ id: ALL_PRODUCT_CATEGORIES, name: 'All Categories' });

        // When adding ALL_CATEGORIES, remove specific categories from product list
        products = products.filter((product) => product.id !== newProduct.id);
      } else {
        // When adding specific categories, remove ALL_CATEGORIES from product list
        products = products.filter((product) => product.id !== newProduct.id || product.category_id !== ALL_PRODUCT_CATEGORIES);
      }

      categories.forEach((category) => {
        const newProductCategory = {
          ...newProduct,
          category_id: category.id,
          category_name: category.name,
        };

        // Check that newProductCategory doesn't already exist
        if (!products.find((product) => product.id === newProductCategory.id && product.category_id === newProductCategory.category_id)) {
          // eslint-disable-next-line arrow-body-style
          products = [...products, newProductCategory].sort((a, b) => {
            return a.name.localeCompare(b.name) || a.category_name.localeCompare(b.category_name);
          });
        } else {
          setErrorText(`You have already added product ${newProductCategory.name} in category ${newProductCategory.category_name}`);
        }
      });

      return {
        ...productList,
        products,
      };
    }));
  };

  const removeProduct = (productId, categoryId) => {
    setProductLists((oldProductLists) => oldProductLists.map((productList) => {
      if (productList.id !== selectedProductList.id) {
        return productList;
      }

      return {
        ...productList,
        products: productList.products.filter((p) => !(p.id === productId && p.category_id === categoryId)),
      };
    }));
  };

  const onRemoveClick = (productId, categoryId) => {
    removeProduct(productId, categoryId);
    axios.delete(`/api/me/product_lists/${selectedProductList.id}/${productId}:${categoryId}`);
    setErrorText(null);
  };

  useEffect(() => {
    const fetchProductCategories = async () => {
      setProductCategories([]);
      setSelectedProductCategories([]);
      if (!selectedProduct) return;

      setIsLoadingProductCategories(true);
      const { data: categories } = await axios.get(`/api/products/${selectedProduct.id}/categories`);
      setIsLoadingProductCategories(false);
      setProductCategories(categories);
    };
    fetchProductCategories();
  }, [selectedProduct]);

  const onProductListChange = async (newProductList) => {
    selectProductList(newProductList);
  };

  const onAddProduct = async () => {
    setErrorText(null);
    setSelectedProduct(null);
    addProduct(selectedProduct, selectedProductCategories);

    const categoryIds = selectedProductCategories.map((cat) => cat.id);

    try {
      await axios.post(`/api/me/product_lists/${selectedProductList.id}`, {id: selectedProduct.id, category_ids: categoryIds});
    } catch (error) {
      if (error.response.status === 500) {
        setErrorText(`Failed to add product ${selectedProduct.reference}`);
      }
      if (error.response.status === 403) {
        setErrorText(`No access to product ${selectedProduct.reference}`);
      }
      removeProduct(selectedProduct.id);
    }
  };

  const onSync = async () => {
    setIsSyncing(true);
    setSyncStatus(null);

    try {
      await fetchAllProducts(true);
      setSyncStatus('success');
    } catch {
      setSyncStatus('error');
    }

    setIsSyncing(false);
  };

  let productCategoriesText = 'All categories';

  if (!selectedProduct) {
    productCategoriesText = '';
  } else if (isLoadingProductCategories) {
    productCategoriesText = 'Loading categories...';
  } else if (selectedProductCategories.length > 0) {
    productCategoriesText = selectedProductCategories.map((cat) => cat.name).join(', ');
  } else if (productCategories.length === 0) {
    productCategoriesText = 'Product has no categories';
  }

  return (
    <div className="card my-products">
      <div className="card-header">My Products</div>
      <div className="card-body">
        <ProductListSelection
          productLists={productLists}
          setProductLists={setProductLists}
          selectedProductList={selectedProductList}
          onChange={onProductListChange}
        />
        {selectedProductList && (
          <>
            {selectedProductList.products.length === 0
              ? <p>This product list has no products</p>
              : (
                <table className="product-table">
                  <thead>
                    <tr>
                      <th>Reference</th>
                      <th>Product Name</th>
                      <th>Category</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedProductList.products.map((product) => (
                      <tr key={`${product.id}:${product.category_id}`}>
                        <td className="col-product-ref">{product.reference}</td>
                        <td className="col-product-name">{product.name}</td>
                        <td className="col-product-cat">
                          {product.category_id === ALL_PRODUCT_CATEGORIES ? 'All Categories' : product.category_name}
                        </td>
                        <td className="col-remove-button">
                          <Button
                            className="remove-button"
                            kind="ghost"
                            size="sm"
                            renderIcon={Close16}
                            iconDescription="Remove Product"
                            tooltipPosition="right"
                            hasIconOnly
                            onClick={() => onRemoveClick(product.id, product.category_id)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            <div className="add-product-section">
              <form>
                <div className="form-row">
                  <ProductDropdown
                    products={allProducts}
                    selectedProduct={selectedProduct}
                    onSelectedProductChange={setSelectedProduct}
                  />
                  <MultiSelect
                    className="category-dropdown"
                    label={productCategoriesText}
                    titleText="Filter by category"
                    id="product-categories"
                    items={productCategories}
                    selectedItems={selectedProductCategories}
                    compareItems={() => 0}
                    sortItems={(items) => items}
                    itemToElement={(cat) => <div style={{marginLeft: cat.depth * 15, fontWeight: cat.has_children ? 'bold' : 'normal'}}>{cat.name}</div>}
                    itemToString={(cat) => cat.name}
                    onChange={({selectedItems}) => setSelectedProductCategories(selectedItems)}
                    disabled={isLoadingProductCategories || productCategories.length === 0}
                  />
                </div>
                <Button
                  type="submit"
                  renderIcon={Add16}
                  size="sm"
                  kind="primary"
                  onClick={(e) => { e.preventDefault(); onAddProduct(); }}
                  disabled={!selectedProduct || isLoadingProductCategories}
                >
                  Add Product
                </Button>
              </form>
            </div>
            {errorText && (
              <div className="error-message">
                {errorText}
              </div>
            )}
          </>
        )}
        <div className="sync-info">
          If you cannot see your product in the dropdown list, it is likely that you do not have the correct access.<br />
          Your <a href="https://w3.ibm.com/w3publisher/rfe-request-for-enhancement/team">Aha! Admin</a> should be able to help resolve this.<br />
          Once this has been resolved, please sync your access here:
        </div>
        <div className="sync-btn-container">
          { isSyncing ? (
            <InlineLoading description="Syncing..." />
          ) : (
            <>
              <Button
                className="sync-btn"
                renderIcon={Renew16}
                size="sm"
                kind="tertiary"
                onClick={onSync}
              >
                Sync product access with Aha!
              </Button>
              <span className="sync-message">
                { syncStatus === 'success' && (<><CheckmarkFilled16 className="sync-message-icon icon-success" /> Product access has been synced with Aha! and the dropdown list above has been updated</>)}
                { syncStatus === 'error' && (<><Error16 className="sync-message-icon icon-failed" /> Failed to sync product access with Aha!</>)}
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyProducts;

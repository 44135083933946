import PropTypes from 'prop-types';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';

const PageTab = ({tab}) => {
  const queryString = useLocation().search;
  const selected = useRouteMatch({path: tab.link});

  return (
    <li
      className={`bx--tabs__nav-item ${selected ? 'bx--tabs__nav-item--selected' : ''}`}
      role="tab"
    >
      <Link
        className="bx--tabs__nav-link"
        to={{
          pathname: tab.link,
          search: queryString,
        }}
        onClick={(e) => e.target.blur()}
      >
        {tab.text}
      </Link>
    </li>
  );
};

const PageTabs = ({tabs}) => (
  <div data-tabs className="bx--tabs">
    <ul className="bx--tabs__nav bx--tabs__nav--hidden" role="tablist">
      {tabs.map((tab) => (
        <PageTab key={tab.link} tab={tab} />
      ))}
    </ul>
  </div>
);

const tabType = PropTypes.shape({
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
});

PageTab.propTypes = {
  tab: tabType.isRequired,
};

PageTabs.propTypes = {
  tabs: PropTypes.arrayOf(tabType.isRequired).isRequired,
};

export default PageTabs;

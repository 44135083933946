import { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import axios from 'axios';
import { useQueryParamArray } from 'hooks/useQueryParam';
import { InlineLoading, RadioButtonGroup, RadioButton, TooltipIcon } from 'carbon-components-react';
import { Information16 } from '@carbon/icons-react';

const IDENTITY_BASES = [
  {key: 'inferred_customer', name: 'Inferred Customer (GBG)'},
  {key: 'company', name: 'Company'},
  {key: 'contact_domain', name: 'Contact Domain'},
];

const NOT_DEFINED_LABEL = '(Not Defined)';
const NOT_DEFINED_KEY = 'not_defined';

const CustomersChartCard = () => {
  const history = useHistory();

  const [selectedIdentityBasis, setSelectedIdentityBasis] = useState(IDENTITY_BASES[0].key);
  const [customerData, setCustomerData] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [hasNoChartDataContent, setHasNoChartDataContent] = useState(false);
  const [productRefs] = useQueryParamArray('product_refs');

  const chartOptions = useMemo(() => {
    const productRefsStr = productRefs ? productRefs.join(',') : '';

    if (chartData !== null && chartData.labels.length === 0) {
      setHasNoChartDataContent(true);
    }

    return {
      indexAxis: 'y',
      scales: {
        x: {
          ticks: {precision: 0},
        },
      },
      maintainAspectRatio: false,
      onHover: (event, activeElements) => {
        event.native.target.style.cursor = (activeElements.length > 0) ? 'pointer' : 'default';
      },
      onClick: (event, activeElements) => {
        if (activeElements.length > 0) {
          let customer = chartData.labels[activeElements[0].index];

          if (customer === NOT_DEFINED_LABEL) {
            customer = NOT_DEFINED_KEY;
          }

          const params = new URLSearchParams({
            product_refs: productRefsStr,
            [selectedIdentityBasis]: customer,
          });

          history.push({
            pathname: '/ideas/all',
            search: params.toString().replaceAll('%2C', ','),
          });
        }
      },
      plugins: {
        legend: { display: false },
      },
    };
  }, [productRefs, chartData, history, selectedIdentityBasis]);

  useEffect(() => {
    if (!productRefs) return;

    const fetchCustomers = async () => {
      const { data: customers } = await axios.get('/api/idea_customers', { params: {
        product_refs: productRefs.join(','),
      }});
      setCustomerData(customers);
    };
    fetchCustomers();
  }, [productRefs]);

  useEffect(() => {
    if (!customerData) return;

    let customers = customerData[selectedIdentityBasis];

    if (selectedIdentityBasis !== 'company') {
      // Remove blank customer name, except for 'Company' identity basis
      customers = customers.filter((customer) => customer.customer_name !== '');
    }

    customers = customers.slice(0, 10);

    const chartLabels = customers.map((customer) => customer.customer_name || NOT_DEFINED_LABEL);
    const chartDatasets = [
      {
        label: 'Ideas',
        data: customers.map((c) => c.count),
        backgroundColor: '#407294',
      },
    ];

    setChartData({
      labels: chartLabels,
      datasets: chartDatasets,
    });
  }, [customerData, selectedIdentityBasis]);

  let display = null;

  if (chartData && !hasNoChartDataContent) {
    display = (<Bar options={chartOptions} data={chartData} />);
  } else if (chartData && hasNoChartDataContent) {
    display = (
      <div className="no-data-for-chart">
        There are no open ideas in the selected products
      </div>
    );
  } else {
    display = (
      <div className="no-data-for-chart">
        <InlineLoading />
        Loading Chart...
      </div>
    );
  }

  return (
    <div className="card">
      <div className="card-header">Open Idea Count by Customer {chartData?.labels.length === 10 && '(Top 10)'}</div>
      <div className="card-body">
        <RadioButtonGroup
          legendText={(
            <>
              Customer Identity Basis
              <TooltipIcon
                className="info-tooltip"
                direction="right"
                tooltipText="Select which field you want to identify the customer by"
                renderIcon={Information16}
              />
            </>
          )}
          name="customer-identity-basis"
          className="customer-identity-basis"
          valueSelected={selectedIdentityBasis}
          onChange={(value) => setSelectedIdentityBasis(value)}
        >
          {IDENTITY_BASES.map((field) => (
            <RadioButton selected labelText={field.name} value={field.key} id={field.key} />
          ))}
        </RadioButtonGroup>
        <div style={{height: '300px'}}>
          {display}
        </div>
      </div>
    </div>
  );
};

export default CustomersChartCard;

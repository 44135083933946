import { useEffect, useContext } from 'react';
import './App.scss';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Content, InlineLoading } from 'carbon-components-react';
import axios from 'axios';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import ShellHeader from './components/ShellHeader/ShellHeader';
import ShellFooter from './components/ShellFooter/ShellFooter';
import IdeasPage from './pages/IdeasPage/IdeasPage';
import BusinessUnitSummaryPage from './pages/BusinessUnitSummaryPage/BusinessUnitSummaryPage';
import BusinessUnitPage from './pages/BusinessUnitPage/BusinessUnitPage';
import SettingsPage from './pages/SettingsPage/SettingsPage';
import SupportPage from './pages/SupportPage/SupportPage';
import SlackResponsePage from './pages/SlackResponsePage/SlackResponsePage';
import TermsAndConditionsPage from './pages/TermsAndConditionsPage/TermsAndConditionsPage';
import UserContext from './UserContext';

const redirectToLogin = () => {
  const currentUrl = window.location.href;
  window.location.href = `/api/sso-login?redirect_to=${encodeURIComponent(currentUrl)}`;
};

function App() {
  const {isLoggedIn, user, setLoggedInUser} = useContext(UserContext);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const { data: userData } = await axios.get('/api/me');
        if (userData) {
          setLoggedInUser(userData);
        } else {
          redirectToLogin();
        }
      } catch (err) {
        setLoggedInUser(null);
        redirectToLogin();
      }
    };

    fetchUser();
  }, [setLoggedInUser]);

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        {isLoggedIn ? (
          <>
            <ShellHeader user={user} />
            <Content>
              <Switch>
                <Route path="/ideas">
                  <IdeasPage />
                </Route>
                <Route path="/business-unit-summary/:utcode">
                  <BusinessUnitPage />
                </Route>
                <Route path="/business-unit-summary">
                  <BusinessUnitSummaryPage />
                </Route>
                <Route path="/settings">
                  <SettingsPage />
                </Route>
                <Route path="/support">
                  <SupportPage />
                </Route>
                <Route path="/terms-and-conditions">
                  <TermsAndConditionsPage />
                </Route>
                <Route path="/slack-response">
                  <SlackResponsePage />
                </Route>
                <Redirect from="/" to="/ideas/summary" exact />
              </Switch>
            </Content>
            <ShellFooter />
          </>
        ) : (
          <div className="logging-in">
            <InlineLoading />
            Logging in...
          </div>
        )}
      </div>
    </Router>
  );
}

export default App;

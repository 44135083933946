import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableContainer,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  TableCell,
  Pagination,
  OverflowMenu,
  OverflowMenuItem,
} from 'carbon-components-react';
import { useState, useEffect, useMemo, useCallback } from 'react';
import debounce from 'lodash.debounce';
import axios from 'axios';
import { useQueryParamArray } from 'hooks/useQueryParam';
import { formatDate } from 'utils/dates';

const tableHeaders = [
  {
    key: 'idea_reference',
    header: 'Idea Reference',
  },
  {
    key: 'name',
    header: 'Idea',
  },
  {
    key: 'raised_by_email',
    header: 'Raised By',
  },
  {
    key: 'product',
    header: 'Product',
  },
  {
    key: 'raised_at',
    header: 'Date Raised',
  },
];

const SpamIdeasListing = () => {
  const [ideaRows, setIdeaRows] = useState([]);
  const [totalIdeas, setTotalIdeas] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortHeaderKey, setSortHeaderKey] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  const [productRefs] = useQueryParamArray('product_refs');

  const fetchIdeas = useCallback(async () => {
    if (!productRefs) return;

    const params = {
      product_refs: productRefs.join(','),
      page: currentPage,
      per_page: pageSize,
      is_spam: true,
    };

    if (sortHeaderKey && sortDirection) {
      params.sort = sortHeaderKey;
      params.sort_direction = sortDirection;
    }

    if (searchQuery !== '') {
      params.query = searchQuery;
    }

    const res = await axios.get('/api/ideas', { params });

    const resIdeas = res.data;
    const resTotalIdeas = parseInt(res.headers['pagination-total-items'], 10);

    const newIdeaRows = resIdeas.map((idea) => ({
      id: idea.id,
      idea_reference: <a href={idea.url}>{idea.idea_reference}</a>,
      product: idea.product,
      name: idea.name,
      raised_by_email: idea.raised_by_email,
      raised_at: formatDate(idea.raised_at),
    }));

    setIdeaRows(newIdeaRows);
    setTotalIdeas(resTotalIdeas);
  }, [productRefs, currentPage, pageSize, sortHeaderKey, sortDirection, searchQuery]);

  useEffect(() => {
    fetchIdeas();
  }, [fetchIdeas]);

  const onHeaderClick = (e, options) => {
    if (options.sortDirection === 'NONE') {
      setSortHeaderKey(null);
      setSortDirection(null);
    } else {
      setSortHeaderKey(options.sortHeaderKey);
      setSortDirection(options.sortDirection.toLowerCase());
    }
    setCurrentPage(1);
  };

  const onSearchChange = (newQuery) => {
    setSearchQuery(newQuery);
  };

  const onSearchChangeDebounced = useMemo(() => debounce(onSearchChange, 500), []);

  const unmarkAsSpam = async (ideaId) => {
    const params = { is_spam: false };
    setIdeaRows((oldIdeaRows) => oldIdeaRows.filter((row) => row.id !== ideaId));
    await axios.patch(`/api/ideas/${ideaId}`, params);
    fetchIdeas();
  };

  return (
    <div className="ideas-listing">
      <DataTable
        rows={ideaRows}
        headers={tableHeaders}
        sortRow={() => null}
        overflowMenuOnHover={false}
      >
        {({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
          <TableContainer>
            <TableToolbar>
              <TableToolbarContent>
                <TableToolbarSearch
                  persistent
                  placeholder="Filter ideas"
                  onChange={(e) => onSearchChangeDebounced(e.target.value)}
                />
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header, onClick: onHeaderClick, isSortable: true })}>
                      {header.header}
                    </TableHeader>
                  ))}
                  <TableHeader />
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow {...getRowProps({ row })}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id} className={`col-${cell.info.header}`}>{cell.value}</TableCell>
                    ))}
                    <TableCell className="bx--table-column-menu">
                      <OverflowMenu size="sm" light flipped>
                        <OverflowMenuItem itemText="Unmark as spam" onClick={() => unmarkAsSpam(row.id)} />
                      </OverflowMenu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DataTable>
      <Pagination
        pageSizes={[10, 20, 50, 100]}
        page={currentPage}
        pageSize={pageSize}
        totalItems={totalIdeas}
        onChange={(pagination) => {
          if (pagination.pageSize !== pageSize) {
            setPageSize(pagination.pageSize);
          }
          setCurrentPage(pagination.page);
        }}
      />
    </div>
  );
};

export default SpamIdeasListing;

import { useQueryParamArray } from 'hooks/useQueryParam';
import { useEffect, useState, useMemo, useContext } from 'react';
import { Link } from 'react-router-dom';
import { TooltipIcon, InlineLoading } from 'carbon-components-react';
import { Information16 } from '@carbon/icons-react';
import axios from 'axios';
import UserContext from 'UserContext';
import TrendChartCard from '../../../components/TrendChartCard/TrendChartCard';
import AssigneesChartCard from './AssigneesChartCard';
import CustomersChartCard from './CustomersChartCard';
import CategoriesChartCard from './CategoriesChartCard';

const buildQueryStr = (params) => {
  const query = new URLSearchParams(params);
  return query.toString().replaceAll('%2C', ',');
};

const SummaryTab = () => {
  const [stats, setStats] = useState(null);
  const [productRefs] = useQueryParamArray('product_refs');
  const {settings} = useContext(UserContext);

  const productRefsStr = useMemo(() => (productRefs ? productRefs.join(',') : ''), [productRefs]);

  const openQueryParamsStr = buildQueryStr({
    product_refs: productRefsStr,
  });

  const overdueQueryParamsStr = buildQueryStr({
    product_refs: productRefsStr,
    sla_info: 'overdue',
  });

  const comingOverdueSubmittedQueryParamsStr = buildQueryStr({
    product_refs: productRefsStr,
    sla_info: 'coming_overdue_submitted',
  });

  const comingOverdueUnderReviewQueryParamsStr = buildQueryStr({
    product_refs: productRefsStr,
    sla_info: 'coming_overdue_under_review',
  });

  useEffect(() => {
    if (!productRefsStr) return;
    const fetchStats = async () => {
      const { data } = await axios.get('/api/idea_stats', {params: {product_refs: productRefsStr}});
      setStats(data);
    };
    fetchStats();
  }, [productRefsStr]);

  return (
    <div className="bx--row summary-tab">
      <div className="bx--col-lg-4">
        <div className="card">
          <div className="card-body">
            {stats ? (
              <>
                <Link className="stat" to={{pathname: '/ideas/all', search: openQueryParamsStr }}>
                  <span className="stat-number">{stats.total_open}</span>
                  <span className="stat-text">Total open ideas</span>
                </Link>
                <Link className="stat" to={{pathname: '/ideas/actionable', search: overdueQueryParamsStr }}>
                  <span className="stat-number">{stats.overdue.total}</span>
                  <span className="stat-text">Overdue ideas</span>
                  <TooltipIcon
                    direction="right"
                    tabIndex={0}
                    tooltipText="Ideas in Submitted older than 30 days or Ideas in Under Review older than 90 days"
                    renderIcon={Information16}
                  />
                </Link>
                <Link className="stat" to={{pathname: '/ideas/actionable', search: comingOverdueSubmittedQueryParamsStr }}>
                  <span className="stat-number">{stats.coming_overdue.submitted}</span>
                  <span className="stat-text">Submitted ideas overdue within {settings.sla_warning_days_submitted} days</span>
                </Link>
                <Link className="stat" to={{pathname: '/ideas/actionable', search: comingOverdueUnderReviewQueryParamsStr }}>
                  <span className="stat-number">{stats.coming_overdue.under_review}</span>
                  <span className="stat-text">Under review ideas overdue within {settings.sla_warning_days_under_review} days</span>
                </Link>
                <p className="stat">
                  <span className="stat-number">{Math.floor(stats.sla_compliance_percent * 100)}%</span>
                  <span className="stat-text">SLA Compliance</span>
                  <TooltipIcon
                    direction="right"
                    tabIndex={0}
                    tooltipText="Percentage of Ideas that are not overdue"
                    renderIcon={Information16}
                  />
                </p>
                <p className="stat">
                  <span className="stat-number">{stats.similar}</span>
                  <span className="stat-text">Unprocessed Similar Ideas</span>
                  <TooltipIcon
                    direction="right"
                    tabIndex={0}
                    tooltipText="We use NLP and ML to check for ideas which may be similar."
                    renderIcon={Information16}
                  />
                </p>
              </>
            ) : (
              <div className="logging-in">
                <InlineLoading />
                Loading Stats...
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="bx--col-lg-8"><TrendChartCard productRefs={productRefs} /></div>
      <div className="bx--col-lg-6"><CustomersChartCard /></div>
      <div className="bx--col-lg-6"><AssigneesChartCard /></div>
      <div className="bx--col-lg-6"><CategoriesChartCard /></div>
    </div>
  );
};

export default SummaryTab;

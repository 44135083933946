import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ComposedModal,
  ModalHeader,
  ModalBody,
  TextArea,
  ModalFooter,
  Dropdown,
  InlineLoading,
  Checkbox,
  Button,
  TextInput,
} from 'carbon-components-react';
import axios from 'axios';
import ComboBoxOrNew from 'components/ComboBoxOrNew/ComboBoxOrNew';

const DEFAULT_PUBLIC_COMMENT = "As part of the review process, we strive to be transparent about our intentions with each enhancement suggestion. The offering team has carefully reviewed this idea and has decided that it does not fit into our current plans, so the idea will be closed. The idea will be kept in IBM's ideas repository and may still be voted on. It might be reassessed or reopened for additional feedback in the future. We value your feedback and thank you for allowing us the privilege of partnering with you in developing our products.";

const BulkUpdateModal = ({ open, onClose, ideas }) => {
  const [publicComment, setPublicComment] = useState(DEFAULT_PUBLIC_COMMENT);
  const [privateComment, setPrivateComment] = useState('');
  const [statuses, setStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [assignees, setAssignees] = useState([]);
  const [assignee, setAssignee] = useState('');
  const [company, setCompany] = useState('');
  const [shouldChangeStatus, setShouldChangeStatus] = useState(false);
  const [shouldChangeAssignee, setShouldChangeAssignee] = useState(false);
  const [shouldUpdateCompany, setShouldUpdateCompany] = useState(false);
  const [shouldAddPublicComment, setShouldAddPublicComment] = useState(false);
  const [shouldAddPrivateComment, setShouldAddPrivateComment] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const canSubmit = shouldChangeStatus || shouldChangeAssignee || shouldUpdateCompany || shouldAddPublicComment || shouldAddPrivateComment;

  useEffect(() => {
    if (open) {
      setShouldChangeStatus(false);
      setShouldChangeAssignee(false);
      setShouldAddPublicComment(false);
      setShouldAddPrivateComment(false);
    }
  }, [open]);

  useEffect(() => {
    setStatuses([]);
    setSelectedStatus(null);

    const fetchStatuses = async () => {
      const productIds = ideas.map((idea) => idea.product_id);
      const uniqueProductIds = [...new Set(productIds)];
      const params = { product_ids: uniqueProductIds.join(',') };
      const { data } = await axios.get('/api/product_statuses', { params });
      setStatuses(data);

      const notUnderConsideration = data.find((status) => status.toLowerCase() === 'not under consideration');

      if (notUnderConsideration) {
        setSelectedStatus(notUnderConsideration);
      } else {
        setSelectedStatus(data[0]);
      }
    };
    fetchStatuses();

    const fetchAssignees = async () => {
      const productRefs = ideas.map((idea) => idea.product_reference);
      const uniqueProductRefs = [...new Set(productRefs)];
      const params = { product_refs: uniqueProductRefs.join(',') };
      const { data } = await axios.get('/api/idea_filters', { params });
      setAssignees(data.assignees);
    };
    fetchAssignees();
  }, [ideas]);

  const ideaRefs = ideas.map((idea, index) => (
    <React.Fragment key={idea.id}>
      <span className="idea-ref">{idea.idea_reference}</span>{index !== ideas.length - 1 && ', '}
    </React.Fragment>
  ));

  const onSubmit = async () => {
    setErrorMessage(null);
    setIsSubmitting(true);

    const bulkUpdate = {
      ids: ideas.map((idea) => idea.id),
    };

    if (shouldChangeStatus) {
      bulkUpdate.status = selectedStatus;
    }

    if (shouldChangeAssignee) {
      if (assignee !== '') {
        bulkUpdate.assignee = assignee;
      } else {
        bulkUpdate.assignee = null;
      }
    }

    if (shouldUpdateCompany) {
      bulkUpdate.company = company;
    }

    if (shouldAddPublicComment) {
      bulkUpdate.public_comment = publicComment;
    }

    if (shouldAddPrivateComment) {
      bulkUpdate.private_comment = privateComment;
    }

    try {
      await axios.patch('/api/ideas', bulkUpdate);
      onClose();
    } catch (err) {
      if ('detail' in err.response.data) {
        setErrorMessage(err.response.data.detail);
      } else {
        setErrorMessage('Failed to update Aha! ideas');
      }
    }

    setIsSubmitting(false);
  };

  return (
    <ComposedModal
      className="bulk-update-modal"
      size="md"
      open={open}
      onClose={onClose}
      preventCloseOnClickOutside
    >
      <ModalHeader>
        <h4>Bulk Update</h4>
      </ModalHeader>
      <ModalBody>
        <p>
          Updating ideas: {ideaRefs}
        </p>
        <div className="update-field">
          <Checkbox
            labelText="Change the status"
            id="form-should-change-status"
            className="enable-update-field"
            checked={shouldChangeStatus}
            onChange={(value) => setShouldChangeStatus(value)}
          />
          <Dropdown
            label=""
            id="set-status"
            disabled={!shouldChangeStatus}
            items={statuses}
            selectedItem={selectedStatus}
            onChange={({selectedItem}) => setSelectedStatus(selectedItem)}
          />
        </div>
        <div className="update-field">
          <Checkbox
            labelText="Change the assignee"
            id="form-should-change-assignee"
            className="enable-update-field"
            checked={shouldChangeAssignee}
            onChange={(value) => setShouldChangeAssignee(value)}
          />
          <ComboBoxOrNew
            label=""
            id="set-assignee"
            disabled={!shouldChangeAssignee}
            items={assignees}
            placeholder="No Assignee"
            value={assignee}
            onValueChange={setAssignee}
          />
        </div>
        <div className="update-field">
          <Checkbox
            labelText="Update the company"
            id="form-should-update-company"
            className="enable-update-field"
            checked={shouldUpdateCompany}
            onChange={(value) => setShouldUpdateCompany(value)}
          />
          <TextInput
            label=""
            id="update-company"
            disabled={!shouldUpdateCompany}
            placeholder=""
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />
        </div>
        <div className="update-field">
          <Checkbox
            labelText="Add a public comment"
            id="form-should-add-public-comment"
            className="enable-update-field"
            checked={shouldAddPublicComment}
            onChange={(value) => setShouldAddPublicComment(value)}
          />
          {shouldAddPublicComment && (
            <TextArea
              labelText=""
              disabled={!shouldAddPublicComment}
              value={publicComment}
              onChange={(event) => setPublicComment(event.target.value)}
              className="comment-textarea"
            />
          )}
        </div>
        <div className="update-field">
          <Checkbox
            labelText="Add a private comment"
            id="form-should-add-private-comment"
            className="enable-update-field"
            checked={shouldAddPrivateComment}
            onChange={(value) => setShouldAddPrivateComment(value)}
          />
          {shouldAddPrivateComment && (
            <TextArea
              labelText=""
              disabled={!shouldAddPrivateComment}
              value={privateComment}
              onChange={(event) => setPrivateComment(event.target.value)}
              className="comment-textarea"
            />
          )}
        </div>
        {errorMessage && (
          <div className="error-message">{errorMessage}</div>
        )}
      </ModalBody>
      <ModalFooter
        onRequestSubmit={onSubmit}
      >
        <Button kind="secondary" disabled={isSubmitting} onClick={onClose}>Cancel</Button>
        <div className="submit-container">
          { isSubmitting ? (
            <InlineLoading
              className="submit-loading"
              description={ideas.length === 1 ? 'Updating idea...' : `Updating ${ideas.length} ideas...`}
            />
          ) : (
            <Button
              kind="primary"
              onClick={onSubmit}
              disabled={!canSubmit}
            >
              {ideas.length === 1 ? 'Update idea' : `Update ${ideas.length} ideas`}
            </Button>
          )}
        </div>
      </ModalFooter>
    </ComposedModal>
  );
};

BulkUpdateModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  ideas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      idea_reference: PropTypes.string.isRequired,
      product_id: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default BulkUpdateModal;

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ComboBox } from 'carbon-components-react';

const ComboBoxOrNew = ({items, value, onValueChange, ...props}) => {
  const [extendedItems, setExtendedItems] = useState(items);
  const [currentlySelectedItem, setCurrentlySelectedItem] = useState(extendedItems[0]);

  useEffect(() => {
    const inputItem = {newText: value};
    setExtendedItems([inputItem, ...items]);
  }, [items, value]);

  const onBlur = () => {
    setCurrentlySelectedItem(extendedItems[0]);
  };

  const itemToString = (item) => {
    if (item === extendedItems[0]) {
      return item.newText;
    }
    return item;
  };

  return (
    <ComboBox
      {...props}
      light
      className="combo-box-or-new"
      items={extendedItems}
      selectedItem={currentlySelectedItem}
      onInputChange={onValueChange}
      onBlur={onBlur}
      itemToString={itemToString}
      shouldFilterItem={({item}) => itemToString(item).startsWith(value) && item !== extendedItems[0]}
    />
  );
};

ComboBoxOrNew.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
};

export default ComboBoxOrNew;

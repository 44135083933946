import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';
import {
  InlineLoading,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
  Checkbox,
  Button,
} from 'carbon-components-react';
import PropTypes from 'prop-types';
import TrendChartCard from 'components/TrendChartCard/TrendChartCard';
import { Download16, ChevronDown16, ChevronRight16 } from '@carbon/icons-react';

const columns = [
  {key: 'product', name: 'Product'},
  {key: 'totalOpen', name: 'Total Open', sortOrder: 'descFirst'},
  {key: 'overdue', name: 'Overdue', sortOrder: 'descFirst'},
  {key: 'slaCompliancePercent', name: 'SLA Compliance'},
  {key: 'submittedOverdue', name: <>Submitted &gt;&nbsp;30d</>, sortOrder: 'descFirst'},
  {key: 'underReviewOverdue', name: <>Under Review &gt;&nbsp;90d</>, sortOrder: 'descFirst'},
];

function getComplianceClass(slaPercent) {
  const roundedPercent = Math.round(slaPercent * 1000) / 10;

  if (roundedPercent >= 99) {
    return 'sla-good';
  }
  if (roundedPercent >= 90) {
    return 'sla-warn';
  }
  return 'sla-bad';
}

function createRowData(isUTItem, id, name, productRefs, currentData, children = [], products = []) {
  const productRefsStr = productRefs.join(',');

  return {
    id,
    isUTItem,
    product: {
      value: name,
      display: (
        <Link to={`/ideas/summary?product_refs=${productRefsStr}`} title={isUTItem ? `UT Code: ${id}` : `Aha! Product Reference: ${productRefsStr}`}>
          {name}
        </Link>
      ),
    },
    totalOpen: {
      value: currentData.total_open,
      display: <Link to={`/ideas/all?product_refs=${productRefsStr}`}>{currentData.total_open}</Link>,
    },
    overdue: {
      value: currentData.overdue,
      display: <Link to={`/ideas/all?product_refs=${productRefsStr}&sla_info=overdue`}>{currentData.overdue}</Link>,
    },
    slaCompliancePercent: {
      value: currentData.sla_compliance_percent,
      display: (
        <div className={`sla-compliance ${getComplianceClass(currentData.sla_compliance_percent)}`}>
          {(Math.round(currentData.sla_compliance_percent * 1000) / 10).toFixed(1)}%
        </div>
      ),
    },
    submittedOverdue: {
      value: currentData.submitted_overdue,
      display: <Link to={`/ideas/all?product_refs=${productRefsStr}&sla_info=overdue_submitted`}>{currentData.submitted_overdue}</Link>,
    },
    underReviewOverdue: {
      value: currentData.under_review_overdue,
      display: <Link to={`/ideas/all?product_refs=${productRefsStr}&sla_info=overdue_under_review`}>{currentData.under_review_overdue}</Link>,
    },
    children,
    products,
  };
}

function createProductRowData(product) {
  return createRowData(false, product.product_id, product.name, [product.reference], product.current);
}

function createUTItemRowData(utitem) {
  const children = utitem.children.map(createUTItemRowData);
  const products = utitem.products.map(createProductRowData);
  return createRowData(true, utitem.utcode, utitem.description, utitem.product_refs, utitem.current, children, products);
}

const sortOrders = {
  ascFirst: ['ASC', 'DESC'],
  descFirst: ['DESC', 'ASC'],
};

const sortMultiplier = {
  ASC: 1,
  DESC: -1,
  NONE: 0,
};

// eslint-disable-next-line arrow-body-style
const SummaryRows = ({row, expandedIds, setExpanded, hideNoIdeas = false, depth = 0}) => {
  const expanded = expandedIds.includes(row.id);

  if (hideNoIdeas && row.totalOpen.value === 0) {
    return null;
  }

  return (
    <>
      <TableRow className={`depth-${depth} ${row.isUTItem ? 'utitem-row' : 'product-row'} ${expanded ? 'row-expanded' : 'row-collapsed'}`}>
        {columns.map((column) => {
          const cellContent = 'display' in row[column.key] ? row[column.key].display : row[column.key].value;
          const showExpandBtn = depth !== 0 && (row.children.length !== 0 || row.products.length !== 0);

          return (
            <TableCell key={column.key} className={`col-${column.key}`}>
              {column.key === 'product' ? (
                <div className="product-container">
                  {showExpandBtn && (
                    <button className="expand-btn" type="button" onClick={() => setExpanded(row.id, !expanded)}>
                      {expanded ? <ChevronDown16 /> : <ChevronRight16 />}
                    </button>
                  )}
                  {row.product.display}
                </div>
              ) : cellContent}
            </TableCell>
          );
        })}
      </TableRow>
      {expanded && (
        <>
          {row.children.map((child) => (
            <SummaryRows key={child.id} row={child} depth={depth + 1} hideNoIdeas={hideNoIdeas} expandedIds={expandedIds} setExpanded={setExpanded} />
          ))}
          {row.products.map((product) => (
            <SummaryRows key={product.id} row={product} depth={depth + 1} hideNoIdeas={hideNoIdeas} expandedIds={expandedIds} setExpanded={setExpanded} />
          ))}
        </>
      )}
    </>
  );
};

SummaryRows.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isUTItem: PropTypes.bool.isRequired,
    totalOpen: PropTypes.shape({
      value: PropTypes.number.isRequired,
    }).isRequired,
    children: PropTypes.arrayOf(PropTypes.shape()),
    products: PropTypes.arrayOf(PropTypes.shape()),
    product: PropTypes.shape({
      display: PropTypes.node.isRequired,
    }).isRequired,
  }).isRequired,
  expandedIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  setExpanded: PropTypes.func.isRequired,
  hideNoIdeas: PropTypes.bool,
  depth: PropTypes.number,
};

SummaryRows.defaultProps = {
  hideNoIdeas: false,
  depth: 0,
};

const BusinessUnitPage = () => {
  const { utcode } = useParams();
  const [businessUnit, setBusinessUnit] = useState(null);
  const [productHierarchy, setProductHierarchy] = useState(null);
  const [productRows, setProductRows] = useState(null);
  const [sortColumn, setSortColumn] = useState('overdue');
  const [sortDirection, setSortDirection] = useState('DESC');
  const [hideNoIdeas, setHideNoIdeas] = useState(true);

  const [expandedIds, setExpandedIds] = useState([utcode]); // Only the top level is expanded by default

  const onExpand = (id) => {
    setExpandedIds((prevExpandedIds) => {
      if (prevExpandedIds.includes(id)) return prevExpandedIds;
      return [...prevExpandedIds, id];
    });
  };

  const onCollapse = (id) => {
    setExpandedIds((prevExpandedIds) => prevExpandedIds.filter((expandedId) => expandedId !== id));
  };

  const setExpanded = (id, isExpanded) => {
    if (isExpanded) {
      onExpand(id);
    } else {
      onCollapse(id);
    }
  };

  const getAllUTCodes = (rows) => {
    const ids = [];

    rows.forEach((row) => {
      ids.push(row.id);
      ids.push(...getAllUTCodes(row.children));
    });

    return ids;
  };

  const expandAll = () => setExpandedIds(getAllUTCodes(productRows));
  const collapseAll = () => setExpandedIds([utcode]);

  const sortComparator = (a, b) => {
    if (typeof a === 'number' && typeof b === 'number') {
      return a - b;
    }

    return new Intl.Collator().compare(a, b);
  };

  const sortRows = (rows) => {
    const newRows = rows.slice().sort((a, b) => sortMultiplier[sortDirection] * sortComparator(a[sortColumn].value, b[sortColumn].value));
    newRows.forEach((row) => {
      if (row.children) { row.children = sortRows(row.children); }
      if (row.products) { row.products = sortRows(row.products); }
    });
    return newRows;
  };

  const sortedProductRows = productRows && sortColumn ? sortRows(productRows) : productRows;

  const onSortChange = (column) => {
    let nextSortDirection;

    const sortOrder = sortOrders[column.sortOrder || 'ascFirst'];

    if (column.key === sortColumn) {
      nextSortDirection = sortOrder[(sortOrder.indexOf(sortDirection) + 1) % sortOrder.length];
    } else {
      [nextSortDirection] = sortOrder;
    }

    setSortColumn(nextSortDirection === 'NONE' ? null : column.key);
    setSortDirection(nextSortDirection);
  };

  useEffect(() => {
    const params = { comparison_period: 'week' };

    const fetchRows = async () => {
      const { data } = await axios.get(`/api/business_unit_summary/${utcode}`);
      setBusinessUnit(data);
    };
    const fetchProducts = async () => {
      const { data } = await axios.get(`/api/business_unit_summary/${utcode}/products`, { params });
      setProductHierarchy(data);
      setProductRows([createUTItemRowData(data)]);
    };
    fetchRows();
    fetchProducts();
  }, [utcode]);

  if (!businessUnit) {
    return (
      <div className="logging-in">
        <InlineLoading />
        Loading Business Unit Summary...
      </div>
    );
  }

  return (
    <div className="bx--grid bx--grid--full-width business-unit-page">
      <div className="bx--row">
        <div className="bx--col header">
          <h2>{businessUnit.business_unit}</h2>
          <div className="business-unit-meta">
            <div className="product-management-vp">
              <strong>Product Management VP:</strong>
              {businessUnit.product_management_vp}
            </div>
            <div className="ideas-champion">
              <strong>
                {businessUnit.ideas_champions.length === 1 ? 'Ideas Champion:' : 'Ideas Champions:'}
              </strong>
              {businessUnit.ideas_champions.join(', ')}
            </div>
          </div>
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col-lg-4">
          <div className="card">
            <div className="card-header">
              Summary Stats
            </div>
            <div className="card-body">
              <div className="stats">
                <div className="stat">
                  <span className="stat-number">{(Math.round(businessUnit.stats_to.sla_compliance_percent * 1000) / 10).toFixed(1)}%</span>
                  <span className="stat-text">SLA Compliance</span>
                </div>
                <div className="stat">
                  <span className="stat-number">{businessUnit.stats_to.total_open}</span>
                  <span className="stat-text">Total open ideas</span>
                </div>
                <div className="stat">
                  <span className="stat-number">{businessUnit.stats_to.overdue}</span>
                  <span className="stat-text">Overdue ideas</span>
                </div>
                <div className="stat">
                  <span className="stat-number">{businessUnit.stats_to.submitted_overdue}</span>
                  <span className="stat-text">Ideas submitted &gt; 30d</span>
                </div>
                <div className="stat">
                  <span className="stat-number">{businessUnit.stats_to.under_review_overdue}</span>
                  <span className="stat-text">Ideas under review &gt; 90d</span>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header">Downloads</div>
            <div className="card-body">
              {productHierarchy ? (
                <>
                  <p className="download-note">Note: These files may be large and take several minutes to download.</p>
                  <ul className="download-links">
                    <li>
                      <a href={`/api/ideas.csv?product_refs=${productHierarchy.product_refs.join(',')}&sla_info=overdue`}>
                        <Download16 /> Download Overdue Ideas CSV
                      </a>
                    </li>
                    <li>
                      <a href={`/api/ideas.csv?product_refs=${productHierarchy.product_refs.join(',')}`}>
                        <Download16 /> Download Open Ideas CSV
                      </a>
                    </li>
                    <li>
                      <a href={`/api/ideas.csv?product_refs=${productHierarchy.product_refs.join(',')}&include_closed=true`}>
                        <Download16 /> Download All Ideas CSV
                      </a>
                    </li>
                    <li>
                      <a href={`/api/business_unit_summary/${utcode}/products.csv`}>
                        <Download16 /> Download Product Summary CSV
                      </a>
                    </li>
                  </ul>
                </>
              ) : (
                <InlineLoading description="Loading Ideas Data..." />
              )}
            </div>
          </div>
        </div>
        <div className="bx--col-lg-8">
          <TrendChartCard productRefs={businessUnit.product_refs} />
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col">
          <div className="card">
            <div className="card-body">
              <div className="product-table-options">
                <Button size="sm" kind="ghost" onClick={expandAll}>Expand All</Button>
                <Button size="sm" kind="ghost" onClick={collapseAll}>Collapse All</Button>
                <Checkbox
                  labelText="Hide products that have no open ideas"
                  id="product-table-hide-no-ideas"
                  checked={hideNoIdeas}
                  onChange={(value) => setHideNoIdeas(value)}
                />
              </div>
              {sortedProductRows ? (
                <Table isSortable size="md">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableHeader
                          key={column.key}
                          className={`col-${column.key}`}
                          isSortable
                          isSortHeader={sortColumn === column.key}
                          sortDirection={sortDirection}
                          onClick={() => onSortChange(column)}
                        >
                          {column.name}
                        </TableHeader>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedProductRows.map((row) => (
                      <SummaryRows key={row.id} row={row} hideNoIdeas={hideNoIdeas} expandedIds={expandedIds} setExpanded={setExpanded} />
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <div className="logging-in">
                  <InlineLoading />
                  Loading Business Unit Products...
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessUnitPage;

import { Link } from 'react-router-dom';
import {
  Header,
  HeaderContainer,
  HeaderGlobalBar,
  HeaderMenu,
  HeaderMenuItem,
  HeaderName,
  HeaderNavigation,
  SkipToContent,
} from 'carbon-components-react';
import { UserAvatar20, Settings16, ChevronDown16, Help16 } from '@carbon/icons-react';
import PropTypes from 'prop-types';

const ShellHeader = ({ user }) => (
  <HeaderContainer
    render={() => (
      <Header className="shell-header" aria-label="IBM Ideas Hub">
        <SkipToContent />
        <HeaderName element={Link} to="/" prefix="IBM">
          Ideas Hub
        </HeaderName>
        <HeaderNavigation className="left-nav" aria-label="Main Nav">
          <HeaderMenuItem element={Link} to="/ideas/summary">Ideas</HeaderMenuItem>
          <HeaderMenuItem element={Link} to="/business-unit-summary">Business Unit Summary</HeaderMenuItem>
        </HeaderNavigation>
        <HeaderGlobalBar>
          <HeaderNavigation className="right-nav" aria-label="Actions Menu">
            <HeaderMenu
              className="profile-menu"
              aria-label="Profile Menu"
              menuLinkName=""
              renderMenuContent={() => (
                <>
                  <span className="user-name">{user.first_name} {user.last_name}</span>
                  <UserAvatar20 />
                  <ChevronDown16 className="down-arrow" />
                </>
              )}
            >
              <HeaderMenuItem className="signed-in-as-item">
                <div>Signed in as</div>
                <div className="user-email">{user.email}</div>
              </HeaderMenuItem>
              <HeaderMenuItem element={Link} to="/settings">
                <Settings16 /> Settings
              </HeaderMenuItem>
              <HeaderMenuItem element={Link} to="/support">
                <Help16 /> Support &amp; Contact
              </HeaderMenuItem>
            </HeaderMenu>
          </HeaderNavigation>
        </HeaderGlobalBar>
      </Header>
    )}
  />
);

ShellHeader.propTypes = {
  user: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
};

export default ShellHeader;

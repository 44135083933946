import { useEffect } from 'react';
import { useQueryParam } from 'hooks/useQueryParam';
import { InlineLoading } from 'carbon-components-react';
import axios from 'axios';

const SlackResponsePage = () => {
  const [errorType] = useQueryParam('error');
  const [code] = useQueryParam('code');

  useEffect(() => {
    const connectToSlack = async () => {
      if (!code) return;

      const redirectURI = `${window.location.origin}/slack-response`;

      const params = {
        code,
        redirect_uri: redirectURI,
      };

      await axios.get('/api/slack/response', { params });
      window.close();
    };

    connectToSlack();
  });

  if (errorType) {
    window.close();
  }

  return (
    <div className="logging-in">
      { errorType ? (
        <>
          Failed to connect to Slack
        </>
      ) : (
        <>
          <InlineLoading />
          Connecting to Slack...
        </>
      )}
    </div>
  );
};

export default SlackResponsePage;

import { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import { Checkbox, InlineLoading } from 'carbon-components-react';
import axios from 'axios';
import { useQueryParamArray } from 'hooks/useQueryParam';

const AssigneesChartCard = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [hasIdeas, setHasIdeas] = useState(false);
  const [showOnlyOverdue, setShowOnlyOverdue] = useState(false);
  const [showOnlyAssigned, setShowOnlyAssigned] = useState(false);
  const [productRefs] = useQueryParamArray('product_refs');

  const chartOptions = useMemo(() => {
    const productRefsStr = productRefs ? productRefs.join(',') : '';

    return {
      indexAxis: 'y',
      scales: {
        x: {
          stacked: true,
          ticks: {precision: 0},
        },
        y: {
          stacked: true,
        },
      },
      maintainAspectRatio: false,
      onHover: (event, activeElements) => {
        event.native.target.style.cursor = (activeElements.length > 0) ? 'pointer' : 'default';
      },
      onClick: (_, activeElements) => {
        if (activeElements.length > 0) {
          const status = chartData.datasets[activeElements[0].datasetIndex].label;
          let assignee = chartData.labels[activeElements[0].index];

          assignee = assignee.replaceAll('*', '');

          if (assignee === 'No Assignee') {
            assignee = 'none';
          }

          const params = new URLSearchParams({
            product_refs: productRefsStr,
            statuses: status,
            assignee,
          });

          if (showOnlyOverdue) {
            params.set('sla_info', 'overdue');
          }
          if (showOnlyAssigned) {
            params.set('assigned', true);
          }

          history.push({
            pathname: '/ideas/all',
            search: params.toString().replaceAll('%2C', ','),
          });
        }
      },
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
            padding: 20,
            boxWidth: 8,
          },
        },
      },
    };
  }, [productRefs, showOnlyOverdue, showOnlyAssigned, history, chartData]);

  useEffect(() => {
    if (!productRefs) return;

    const fetchAssignees = async () => {
      setIsLoading(true);

      const params = {product_refs: productRefs.join(',')};

      if (showOnlyOverdue) {
        params.sla_info = 'overdue';
      }
      if (showOnlyAssigned) {
        params.assigned = true;
      }

      const { data: assignees } = await axios.get('/api/idea_assignee_states', { params });
      setHasIdeas(assignees.length > 0);

      const chartLabels = assignees.map((assignee) => {
        let assigneeEmail = assignee.assignee_email;

        if (assigneeEmail === '') {
          assigneeEmail = 'No Assignee';
        }

        if (assignee.is_default_assignee) {
          assigneeEmail += '*';
        }

        if (assignee.has_left_ibm) {
          assigneeEmail += '†';
        }

        return assigneeEmail;
      });

      const chartDatasets = [];

      assignees.forEach((assignee) => {
        assignee.states.forEach((state) => {
          const dataset = chartDatasets.find((d) => d.label === state.name);
          if (!dataset) {
            chartDatasets.push({
              label: state.name,
              data: [],
              backgroundColor: state.color,
            });
          }
        });
      });
      chartDatasets.forEach((dataset) => {
        assignees.forEach((assignee) => {
          const state = assignee.states.find((s) => s.name === dataset.label);

          if (state) {
            dataset.data.push(state.count);
          } else {
            dataset.data.push(0);
          }
        });
      });

      setChartData({
        labels: chartLabels,
        datasets: chartDatasets,
      });
      setIsLoading(false);
    };
    fetchAssignees();
  }, [productRefs, showOnlyOverdue, showOnlyAssigned]);

  let display = null;

  if (isLoading) {
    display = (
      <div className="no-data-for-chart" style={{height: 300}}>
        <InlineLoading />
        Loading Chart...
      </div>
    );
  } else if (!hasIdeas) {
    display = (
      <div className="no-data-for-chart" style={{height: 300}}>
        There are no open ideas in the selected products and filters
      </div>
    );
  } else {
    display = (
      <div>
        <div style={{height: 300}}>
          <Bar options={chartOptions} data={chartData} />
        </div>
        <div className="defaultAssigneeNotice">
          * default assignee
        </div>
        <div className="defaultAssigneeNotice">
          &dagger; assignee has left IBM
        </div>
      </div>
    );
  }

  return (
    <div className="card idea-status-by-assignees-card">
      <div className="card-header">Open Idea Status by Assignees {chartData?.labels.length === 10 && '(Top 10)'}</div>
      <div className="card-body">
        <div className="checkboxes">
          <div className="overdue-checkbox">
            <Checkbox
              labelText="Show only overdue ideas"
              id="filter-overdue"
              checked={showOnlyOverdue}
              onChange={(value) => setShowOnlyOverdue(value)}
            />
          </div>
          <div className="assignee-checkbox">
            <Checkbox
              labelText="Show only ideas with assignees"
              id="filter-no-assignee"
              checked={showOnlyAssigned}
              onChange={(value) => setShowOnlyAssigned(value)}
            />
          </div>
        </div>
        {display}
      </div>
    </div>
  );
};

export default AssigneesChartCard;

import PropTypes from 'prop-types';
import { LogoSlack20 } from '@carbon/icons-react';
import { Button, InlineLoading } from 'carbon-components-react';
import { useEffect, useRef, useContext, useState } from 'react';
import axios from 'axios';
import UserContext from 'UserContext';

const AddToSlackButton = ({ isConnected }) => {
  const { updateSetting, refreshLoggedInUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const timerRef = useRef();
  const windowRef = useRef();

  useEffect(() => (() => {
    // Clear timeout when unmounting component
    clearTimeout(timerRef.current);
  }));

  const openSlackAuthorize = () => {
    const redirectURI = `${window.location.origin}/slack-response`;

    const params = new URLSearchParams({
      client_id: process.env.REACT_APP_SLACK_CLIENT_ID,
      redirect_uri: redirectURI,
      scope: 'chat:write',
      user_scope: '',
    });

    const width = 650;
    const height = 650;
    const left = window.outerWidth / 2 + window.screenX - (width / 2);
    const top = window.outerHeight / 2 + window.screenY - (height / 2);

    windowRef.current = window.open(`https://slack.com/oauth/v2/authorize?${params}`, 'slackAuthorize', `popup=yes,toolbar=no,width=${width},height=${height},left=${left},top=${top}`);

    // Clear timeout before creating a new one (in case two windows opened)
    clearTimeout(timerRef.current);

    const checkWindowStatus = async () => {
      if (windowRef.current.closed) {
        // If the window is closed, refresh user to check if slack is enabled
        setIsLoading(true);
        await refreshLoggedInUser();
        setIsLoading(false);
      } else {
        // If the window is not closed, check again after timeout
        timerRef.current = setTimeout(checkWindowStatus, 100);
      }
    };

    checkWindowStatus();
  };

  const disconnectFromSlack = async () => {
    await axios.get('/api/slack/disconnect');
    updateSetting('is_slack_connected', false);
  };

  const button = (
    isConnected ? (
      <Button onClick={disconnectFromSlack} size="sm" kind="tertiary">
        <LogoSlack20 /> Disconnect from Slack
      </Button>
    ) : (
      <Button onClick={openSlackAuthorize} size="sm" kind="tertiary">
        <LogoSlack20 /> Connect to Slack
      </Button>
    )
  );

  return (
    <div className="slack-connect-button-container">
      {isLoading ? (
        <InlineLoading description="Checking Slack status" />
      ) : button}
    </div>
  );
};

AddToSlackButton.propTypes = {
  isConnected: PropTypes.bool.isRequired,
};

export default AddToSlackButton;

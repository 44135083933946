import MyProducts from './MyProducts';
import MyWarningPeriods from './MyWarningPeriods';
import MyNotifications from './MyNotifications';

const SettingsPage = () => (
  <div className="bx--grid bx--grid--full-width settings-page">
    <div className="bx--row">
      <div className="bx--col header">
        <h2>Settings</h2>
      </div>
    </div>
    <div className="bx--row">
      <div className="bx--col-lg-12">
        <MyProducts />
      </div>
    </div>
    <div className="bx--row">
      <div className="bx--col-lg-12">
        <MyWarningPeriods />
      </div>
    </div>
    <div className="bx--row">
      <div className="bx--col-lg-12">
        <MyNotifications />
      </div>
    </div>
  </div>
);

export default SettingsPage;

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

function useQuery() {
  const location = useLocation();
  return useMemo(() => new URLSearchParams(location.search), [location.search]);
}

export const useQueryParam = (key) => {
  const history = useHistory();
  const query = useQuery();
  const value = query.get(key);
  const [queryParam, setQueryParam] = useState(value);

  useEffect(() => {
    const newValue = query.get(key);
    setQueryParam(newValue);
  }, [key, query]);

  const updateURL = useCallback((newValue) => {
    setQueryParam(newValue);

    if (newValue === null) {
      query.delete(key);
    } else {
      query.set(key, newValue);
    }

    const newQueryString = query.toString().replaceAll('%2C', ',');

    history.push({ search: newQueryString });
  }, [history, key, query]);

  return [queryParam, updateURL];
};

const queryParamStrToArray = (str) => {
  if (!str) return null;
  return str.split(',');
};

const queryParamArrayToStr = (arr) => {
  if (!arr) return null;
  return arr.join(',');
};

export const useQueryParamArray = (key) => {
  const [queryParam, setQueryParam] = useQueryParam(key);
  const [queryParamArray, setQueryParamArray] = useState(queryParamStrToArray(queryParam));

  useEffect(() => {
    const newValueArray = queryParamStrToArray(queryParam);
    setQueryParamArray((oldValueArray) => {
      if (oldValueArray && newValueArray
          && oldValueArray.length === newValueArray.length
          && oldValueArray.every((v, i) => newValueArray[i] === v)) {
        return oldValueArray;
      }
      return newValueArray;
    });
  }, [queryParam]);

  const updateURL = useCallback((newValue) => {
    const newValueStr = queryParamArrayToStr(newValue);
    setQueryParam(newValueStr);
  }, [setQueryParam]);

  return [queryParamArray, updateURL];
};

export const useQueryParamBool = (key) => {
  const [queryParam, setQueryParam] = useQueryParam(key);
  const [queryParamBool, setQueryParamBool] = useState(queryParam === 'true');

  useEffect(() => {
    setQueryParamBool(queryParam === 'true');
  }, [queryParam]);

  const updateURL = useCallback((newValue) => {
    // If newValue is true, add key=true to URL, otherwise remove key from URL
    const newValueStr = newValue ? 'true' : null;
    setQueryParam(newValueStr);
  }, [setQueryParam]);

  return [queryParamBool, updateURL];
};

import { lighten } from 'utils/colors';
import PropTypes from 'prop-types';

const StatusPill = ({ name, color }) => (
  <span
    className="status-pill"
    style={{
      borderColor: color,
      backgroundColor: lighten(color, 0.7),
    }}
  >
    {name}
  </span>
);

StatusPill.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default StatusPill;

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dropdown,
  ComposedModal,
  ModalHeader,
  ModalBody,
  TextInput,
  ModalFooter,
} from 'carbon-components-react';
import { Add16, Edit16, TrashCan16 } from '@carbon/icons-react';
import ReactDOM from 'react-dom';
import axios from 'axios';

const ProductListSelection = ({ productLists, setProductLists, selectedProductList, onChange }) => {
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [newProductListName, setNewProductListName] = useState('');
  const [deletingProductListName, setDeletingProductListName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (isRenameModalOpen && selectedProductList) {
      setNewProductListName(selectedProductList.name);
    }
  }, [isRenameModalOpen, selectedProductList]);

  useEffect(() => {
    if (isCreateModalOpen) {
      setNewProductListName('');
    }
  }, [isCreateModalOpen]);

  const onRename = async () => {
    await axios.patch(`/api/me/product_lists/${selectedProductList.id}`, { name: newProductListName });
    setProductLists((oldProductLists) => oldProductLists.map((productList) => {
      if (productList.id !== selectedProductList.id) {
        return productList;
      }

      return {
        ...productList,
        name: newProductListName,
      };
    }));
    setIsRenameModalOpen(false);
  };

  const onCreate = async () => {
    setErrorMessage('');
    try {
      const { data: newProductList } = await axios.post('/api/me/product_lists', { name: newProductListName });
      setProductLists((oldProductLists) => [...oldProductLists, newProductList].sort((a, b) => a.name.localeCompare(b.name)));
      onChange(newProductList);
      setIsCreateModalOpen(false);
    } catch (err) {
      if ('detail' in err.response.data) {
        setErrorMessage(err.response.data.detail);
      } else {
        setErrorMessage('Failed to create product list');
      }
    }
  };

  const onDelete = async () => {
    await axios.delete(`/api/me/product_lists/${selectedProductList.id}`);
    const newProductLists = productLists.filter((pl) => pl.id !== selectedProductList.id);
    setProductLists(newProductLists);
    onChange(newProductLists[0]);
    setIsDeleteModalOpen(false);
  };

  const onChangeSelection = (selectedItem) => {
    onChange(selectedItem);
  };

  return (
    <div className="product-list-selection">
      <Dropdown
        id="product-list-dropdown"
        type="inline"
        titleText="Product List:"
        label="Loading..."
        items={productLists}
        itemToString={(item) => item.name}
        selectedItem={selectedProductList}
        size="sm"
        onChange={({selectedItem}) => onChangeSelection(selectedItem)}
      />
      <>
        {typeof document === 'undefined'
          ? null
          : ReactDOM.createPortal(
            <ComposedModal
              open={isRenameModalOpen}
              onClose={() => setIsRenameModalOpen(false)}
            >
              <ModalHeader title="Rename Product List" />
              <ModalBody hasForm onSubmit={onRename}>
                <TextInput
                  id="rename-product-list-name"
                  data-modal-primary-focus
                  labelText="New product list name:"
                  value={newProductListName}
                  onChange={(e) => setNewProductListName(e.target.value)}
                />
              </ModalBody>
              <ModalFooter
                onRequestSubmit={() => onRename()}
                primaryButtonText="Rename"
                primaryButtonDisabled={newProductListName === ''}
                secondaryButtonText="Cancel"
              />
            </ComposedModal>,
            document.body,
          )}
        <Button
          hasIconOnly
          renderIcon={Edit16}
          iconDescription="Rename this product list"
          size="sm"
          kind="ghost"
          onClick={() => setIsRenameModalOpen(true)}
        />
      </>
      <>
        {typeof document === 'undefined'
          ? null
          : ReactDOM.createPortal(
            <ComposedModal
              open={isDeleteModalOpen}
              onClose={() => setIsDeleteModalOpen(false)}
            >
              <ModalHeader title={`Are you sure you want to delete the product list '${deletingProductListName}'?`} />
              <ModalFooter
                onRequestSubmit={() => onDelete()}
                primaryButtonText="Delete Product List"
                danger
                secondaryButtonText="Cancel"
              />
            </ComposedModal>,
            document.body,
          )}
        <Button
          hasIconOnly
          renderIcon={TrashCan16}
          iconDescription="Delete this product list"
          size="sm"
          kind="ghost"
          onClick={() => { setDeletingProductListName(selectedProductList.name); setIsDeleteModalOpen(true); }}
          disabled={productLists.length === 1}
        />
      </>
      <>
        {typeof document === 'undefined'
          ? null
          : ReactDOM.createPortal(
            <ComposedModal
              className="create-product-list-modal"
              open={isCreateModalOpen}
              onClose={() => setIsCreateModalOpen(false)}
            >
              <ModalHeader title="Create Product List" />
              <ModalBody hasForm onSubmit={onCreate}>
                <TextInput
                  id="new-product-list-name"
                  data-modal-primary-focus
                  labelText="New product list name:"
                  value={newProductListName}
                  onChange={(e) => setNewProductListName(e.target.value)}
                  onSubmit={() => onCreate()}
                />
                {errorMessage && (
                  <div className="error-message">{errorMessage}</div>
                )}
              </ModalBody>
              <ModalFooter
                onRequestSubmit={() => onCreate()}
                primaryButtonText="Create Product List"
                primaryButtonDisabled={newProductListName === ''}
                secondaryButtonText="Cancel"
              />
            </ComposedModal>,
            document.body,
          )}
        <Button
          hasIconOnly
          renderIcon={Add16}
          iconDescription="Create a new list product list"
          size="sm"
          kind="ghost"
          onClick={() => setIsCreateModalOpen(true)}
        />
      </>
    </div>
  );
};

const ProductListType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
});

ProductListSelection.propTypes = {
  productLists: PropTypes.arrayOf(ProductListType).isRequired,
  setProductLists: PropTypes.func.isRequired,
  selectedProductList: ProductListType,
  onChange: PropTypes.func.isRequired,
};

ProductListSelection.defaultProps = {
  selectedProductList: null,
};

export default ProductListSelection;

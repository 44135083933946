import { createContext, useState, useCallback, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import axios from 'axios';

const UserContext = createContext();

export const UserProvider = ({children}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [settings, setSettings] = useState(null);
  const [productLists, setProductLists] = useState(null);
  const [selectedProductList, setSelectedProductList] = useState(null);
  const [listingColumns, setListingColumns] = useState(null);

  useEffect(() => {
    if (!settings || !productLists) {
      setSelectedProductList(null);
      return;
    }

    const selectedProductListID = settings.selected_product_list_id;
    const newSelectedProductList = productLists.find((productList) => productList.id === selectedProductListID);
    setSelectedProductList(newSelectedProductList);
  }, [settings, productLists, setSelectedProductList]);

  const setLoggedInUser = useCallback((userData) => {
    if (!userData) {
      setIsLoggedIn(false);
      setUser(null);
      setSettings(null);
      setProductLists(null);
      setListingColumns(null);
    } else {
      setUser(userData.user);
      setSettings(userData.settings);
      setProductLists(userData.product_lists);
      setListingColumns(userData.listing_columns);
      setIsLoggedIn(true);
    }
  }, [setUser, setSettings, setProductLists]);

  const refreshLoggedInUser = useCallback(async () => {
    const { data: userData } = await axios.get('/api/me');
    setLoggedInUser(userData);
  }, [setLoggedInUser]);

  const updateSetting = useCallback(async (key, value) => {
    // Update settings locally first
    setSettings((oldSettings) => ({
      ...oldSettings,
      [key]: value,
    }));

    const { data: newSettings } = await axios.patch('/api/me/settings', {[key]: value});

    // When response comes back from server, update locally to ensure that they reconcile
    setSettings(newSettings);
  }, [setSettings]);

  const selectProductList = useCallback((newProductList) => {
    updateSetting('selected_product_list_id', newProductList.id);
  }, [updateSetting]);

  const updateListingColumns = useCallback(async (newListingColumns) => {
    setListingColumns(newListingColumns);
    await axios.put('/api/me/listing_columns', newListingColumns);
  }, [setListingColumns]);

  const userContext = {
    isLoggedIn,
    user,
    settings,
    productLists,
    selectedProductList,
    selectProductList,
    setLoggedInUser,
    refreshLoggedInUser,
    updateSetting,
    setProductLists,
    listingColumns,
    updateListingColumns,
  };

  return (
    <UserContext.Provider value={userContext}>
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserContext;

import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import {
  ComposedModal,
  ModalHeader,
  ModalBody,
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableExpandHeader,
  TableExpandRow,
  TableExpandedRow,
  TableHeader,
  TableBody,
  TableCell,
  RadioButton,
  Button,
  InlineLoading,
} from 'carbon-components-react';
import { Launch16 } from '@carbon/icons-react';
import axios from 'axios';
import StatusPill from 'components/StatusPill/StatusPill';

const TABLE_HEADERS = [
  {key: 'idea_reference', header: 'Idea Reference'},
  {key: 'name', header: 'Idea'},
  {key: 'status', header: 'Status'},
  {key: 'similarity_score', header: 'Similarity Score'},
];

const SimilarIdeasTable = ({thisIdea, ideas, ideaActions, setIdeaActions}) => {
  const thisIdeaId = thisIdea.id;

  const ideaRows = ideas.map((idea) => {
    let similarityPercentStr;

    if (idea.id === thisIdeaId) {
      similarityPercentStr = 'This idea';
    } else {
      similarityPercentStr = Math.round(idea.similarity_score * 100);
      similarityPercentStr += '%';
    }

    return {
      ...idea,
      idea_reference: <a href={idea.url}>{idea.idea_reference}</a>,
      status: <StatusPill name={idea.status} color={idea.status_color} />,
      similarity_score: similarityPercentStr,
    };
  });

  const getIdeaDescription = (id) => {
    const idea = ideas.find((i) => i.id === id);
    if (!idea) return 'Testing';
    return idea.description;
  };

  const getIdeaAction = (id) => {
    const ideaAction = ideaActions.find((i) => i.id === id);
    if (!ideaAction) return null;
    return ideaAction.action;
  };

  const onChangeIdeaAction = (id, action) => {
    setIdeaActions((oldIdeaActions) => {
      let newIdeaActions = [...oldIdeaActions];

      // If this action is parent, change any other parents to children
      if (action === 'parent') {
        newIdeaActions.forEach((ideaAction) => {
          if (ideaAction.action === 'parent') {
            ideaAction.action = 'child';
          }
        });
      }

      // Update this row's idea action
      const oldIdeaAction = newIdeaActions.find((ideaAction) => ideaAction.id === id);

      if (oldIdeaAction) {
        oldIdeaAction.action = action;
      } else {
        newIdeaActions.push({id, action});
      }

      // If all ideas are marked as not similar, remove parent/child from 'this idea'
      if (action === 'not_similar') {
        const notSimilarActions = newIdeaActions.filter((ideaAction) => ideaAction.action === 'not_similar');

        if (notSimilarActions.length === ideas.length - 1) {
          newIdeaActions = newIdeaActions.filter((ideaAction) => ideaAction.id !== thisIdeaId);
        }
      }

      return newIdeaActions;
    });
  };

  return (
    <DataTable rows={ideaRows} headers={TABLE_HEADERS}>
      {({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
        <Table {...getTableProps()}>
          <TableHead>
            <TableRow>
              <TableExpandHeader />
              {headers.map((header) => (
                <TableHeader {...getHeaderProps({ header })}>
                  {header.header}
                </TableHeader>
              ))}
              <TableHeader>Parent</TableHeader>
              <TableHeader>Child</TableHeader>
              <TableHeader>Not Similar</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <React.Fragment key={row.id}>
                <TableExpandRow {...getRowProps({ row, className: row.id === thisIdeaId ? 'this-idea' : '' })}>
                  {row.cells.map((cell) => (
                    <TableCell key={cell.id}>{cell.value}</TableCell>
                  ))}
                  <TableCell>{ideas.length > 1 && <RadioButton onChange={() => onChangeIdeaAction(row.id, 'parent')} checked={getIdeaAction(row.id) === 'parent'} />}</TableCell>
                  <TableCell>{ideas.length > 1 && <RadioButton onChange={() => onChangeIdeaAction(row.id, 'child')} checked={getIdeaAction(row.id) === 'child'} />}</TableCell>
                  <TableCell>{row.id !== thisIdeaId && <RadioButton onChange={() => onChangeIdeaAction(row.id, 'not_similar')} checked={getIdeaAction(row.id) === 'not_similar'} />}</TableCell>
                </TableExpandRow>
                <TableExpandedRow
                  colSpan={headers.length + 4}
                >
                  <div className="idea-description">
                    <strong>Description: </strong>
                    {getIdeaDescription(row.id)}
                  </div>
                </TableExpandedRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      )}
    </DataTable>
  );
};

const ideaType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  idea_reference: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  status: PropTypes.string.isRequired,
  status_color: PropTypes.string.isRequired,
  similarity_score: PropTypes.number,
});

SimilarIdeasTable.propTypes = {
  thisIdea: ideaType.isRequired,
  ideas: PropTypes.arrayOf(ideaType).isRequired,
  ideaActions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
  })).isRequired,
  setIdeaActions: PropTypes.func.isRequired,
};

const SimilarIdeasModal = ({idea, onClose}) => {
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [similarIdeas, setSimilarIdeas] = useState([]);

  const [ideaActions, setIdeaActions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [isSuccessful, setIsSuccessful] = useState(false);

  const fetchSimilarIdeas = useCallback(async () => {
    const { data } = await axios.get(`/api/ideas/${idea.id}/similar_ideas`);
    setSimilarIdeas(data);
  }, [idea.id, setSimilarIdeas]);

  useEffect(() => {
    if (!open) return;
    fetchSimilarIdeas();
  }, [open, fetchSimilarIdeas]);

  useEffect(() => {
    if (ideaActions.length === 0) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [ideaActions]);

  const onResetClick = async () => {
    setIdeaActions([]);
  };

  const onSubmitClick = async () => {
    setError(null);
    setIsSuccessful(false);
    setDisabled(false);

    const notSimilarActions = ideaActions.filter((ideaAction) => ideaAction.action === 'not_similar');
    const parentAction = ideaActions.find((ideaAction) => ideaAction.action === 'parent');
    const childActions = ideaActions.filter((ideaAction) => ideaAction.action === 'child');

    if (ideaActions.length === 0) {
      setError('No selections');
      return;
    }

    if (childActions.length > 0 && !parentAction) {
      setError('No parent selected');
      return;
    }

    if (parentAction && childActions.length === 0) {
      setError('No children selected');
      return;
    }

    const updates = [];

    childActions.forEach((childAction) => {
      // Setting ${childAction.id} as duplicate of ${parentAction.id}
      updates.push({
        idea_id_a: childAction.id,
        idea_id_b: parentAction.id,
        response: 'duplicate',
      });
    });

    notSimilarActions.forEach((notSimilarAction) => {
      // Setting ${notSimilarAction.id} as not similar to ${thisIdeaId}
      updates.push({
        idea_id_a: notSimilarAction.id,
        idea_id_b: idea.id,
        response: 'not_similar',
      });
    });

    setIsSubmitting(true);
    try {
      await axios.patch('/api/similar_ideas', updates);
      await fetchSimilarIdeas();
      setIsSuccessful(true);
    } catch (err) {
      if (err.response.data.detail === 'Circular merges are not allowed') {
        setError('Unable to submit. At least one of these ideas has already been marked as a duplicate.');
        setDisabled(true);
        setIdeaActions([]);
      } else {
        setError('Failed to update ideas');
      }
    }
    if ((similarIdeas.length - updates.length) === 1) {
      setDisabled(true);
    }
    setIdeaActions([]);
    setIsSubmitting(false);
  };

  return (
    <>
      {typeof document === 'undefined'
        ? null
        : ReactDOM.createPortal(
          <ComposedModal className="similar-ideas-modal" size="lg" open={open} onClose={() => { setOpen(false); onClose(); }}>
            <ModalHeader>
              <h4>Similar Ideas for idea: {idea.idea_reference}</h4>
            </ModalHeader>
            <ModalBody>
              <p className="modal-description">
                The following ideas have been identified as similar to {idea.idea_reference}.<br />
                Please register whether that similarity is correct and the ideas are to be merged as duplicates in Aha,
                or conversely  that you&apos;d like to ignore this similarity.
              </p>
              <SimilarIdeasTable
                thisIdea={idea}
                ideas={similarIdeas}
                ideaActions={ideaActions}
                setIdeaActions={setIdeaActions}
              />
              <div className="merge-ideas-button-container">

                {isSubmitting ? (
                  <Button className="reset-ideas-button" onClick={onResetClick} disabled="true" type="reset" kind="ghost">Reset</Button>
                ) : (
                  <Button className="reset-ideas-button" onClick={onResetClick} disabled={disabled} type="reset" kind="ghost">Reset</Button>
                )}
                {isSubmitting ? (
                  <InlineLoading description="Submitting..." />
                ) : (
                  <Button className="submit-ideas-button" onClick={onSubmitClick} disabled={disabled} kind="primary">Submit</Button>
                )}

              </div>
              {error && (
                <div className="error-message">{error}</div>
              )}
              {isSuccessful && (
                <div>Similar ideas have been updated</div>
              )}
              {similarIdeas.length === 1 && (
                <div>There are no more similar ideas</div>
              )}
            </ModalBody>
          </ComposedModal>,
          document.body,
        )}
      <button type="button" className="open-similar-ideas-button" onClick={() => setOpen(true)}>
        <Launch16 />
      </button>
    </>
  );
};

SimilarIdeasModal.propTypes = {
  idea: PropTypes.shape({
    idea_reference: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SimilarIdeasModal;

import { useState } from 'react';
import axios from 'axios';
import { Button, InlineLoading } from 'carbon-components-react';

const TestSlackNotificationButton = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  const testSlackNotification = async () => {
    setIsLoading(true);
    setIsError(false);
    setIsSuccess(false);

    try {
      await axios.get('/api/slack/test');
      setIsSuccess(true);
    } catch (error) {
      setIsError(true);
    }

    setIsLoading(false);
  };

  if (isLoading) {
    return <InlineLoading description="Sending Slack notification..." />;
  }

  if (isSuccess) {
    return <InlineLoading description="Slack notification sent!" status="finished" />;
  }

  if (isError) {
    return <InlineLoading description="Failed to send Slack notification" status="error" />;
  }

  return (
    <Button size="sm" kind="ghost" onClick={testSlackNotification}>Test Slack Notification</Button>
  );
};

export default TestSlackNotificationButton;

const TermsAndConditionsPage = () => (
  <div className="bx--grid bx--grid--full-width support-page">
    <div className="bx--row">
      <div className="bx--col header">
        <h2>Terms &amp; Conditions</h2>
      </div>
    </div>
    <div className="bx--row">
      <div className="bx--col-lg-12">
        <div className="card">
          <div className="card-body">
            <p>
              The IBM Ideas database, its contents, and related documentation is accessible to IBM employees and
              other individuals authorized by IBM to access it. The database and its contents is IBM Confidential
              and may only be used for IBM internal business purposes, to collect and review of information
              regarding product features suggestions about potential product enhancements. Use of IBM&apos;s systems
              is restricted to management approved purposes and are subject to all applicable policies and
              guidelines, including the IBM Business Conduct Guidelines, Corporate Instructions (including, but
              not limited to LEG116: Classification and Control of IBM Information: <a href="https://w3.ibm.com/ibm/corporate-documents/leg/leg-116">https://w3.ibm.com/ibm/corporate-documents/leg/leg-116</a>),
              and End User Acceptable Use Requirements of the IT Security Standard (<a href="https://pages.github.ibm.com/ciso-psg/main/standards/itss.html#euaur">https://pages.github.ibm.com/ciso-psg/main/standards/itss.html#euaur</a>).
              User Content is the content that employees and other authorized
              users post or upload to the IBM Ideas database, which would include, for example, certain profile
              and contact data, personal information, submissions, ideas, tags, messages, recommendations, posts,
              comments, and responses.
            </p>

            <p>
              IBM and other participants may use the User Content that you post for business purposes, may create
              derivative works of your User Content and may sublicense others to do the same. You agree to only
              post User Content that you created or developed or that you have permission or authorization to
              post, including personal information about others.
            </p>

            <p>
              Through the IBM Ideas database, you may have access to personal information of others. You may only
              use and disclose that information as permitted by IBM policies or practices. Do not post third
              party confidential information, and do not post third party materials without permission from the
              owner of that material.
            </p>

            <p>
              By using the IBM Ideas database you consent to IBM&apos;s collection and storage of your personal
              information in the submission record, for the purpose of the collection and review of information
              regarding product features suggestions about potential product enhancements. Read the IBM Internal
              Privacy Statement: <a href="https://w3.ibm.com/w3publisher/w3-privacy-notice/">https://w3.ibm.com/w3publisher/w3-privacy-notice/</a> for
              details of how IBM protects and uses your personal information.
            </p>

            <p>
              IBM Internal Privacy Statement: <a href="https://w3.ibm.com/w3publisher/w3-privacy-notice/">https://w3.ibm.com/w3publisher/w3-privacy-notice/</a><br />
              IBM Business Conduct Guidelines: <a href="https://w3.ibm.com/w3publisher/trust-compliance/bcgs">https://w3.ibm.com/w3publisher/trust-compliance/bcgs</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default TermsAndConditionsPage;

import { PropTypes } from 'prop-types';

function getDirectionClass(delta) {
  if (delta > 0) return 'direction-up';
  if (delta < 0) return 'direction-down';
  return 'direction-none';
}

function getDeltaClass(delta, goodDirection) {
  const positiveDelta = goodDirection === 'up' ? delta : -delta;
  if (positiveDelta > 0) return 'delta-good';
  if (positiveDelta < 0) return 'delta-bad';
  return 'delta-none';
}

const NumberDelta = ({ value, goodDirection, suffix }) => {
  const directionClass = getDirectionClass(value);
  const deltaClass = getDeltaClass(value, goodDirection);
  const classNames = `number-delta ${directionClass} ${deltaClass}`;

  return (
    <span className={classNames}>{value}{suffix}</span>
  );
};

NumberDelta.propTypes = {
  value: PropTypes.number.isRequired,
  goodDirection: PropTypes.oneOf(['up', 'down']).isRequired,
  suffix: PropTypes.string,
};

NumberDelta.defaultProps = {
  suffix: '',
};

export default NumberDelta;

import ReactDOM from 'react-dom';
import { Switch, Route, Link } from 'react-router-dom';
import { Dropdown, Button, ComposedModal, ModalHeader, ModalBody } from 'carbon-components-react';
import PageTabs from 'components/PageTabs/PageTabs';
import { useQueryParamArray } from 'hooks/useQueryParam';
import { formatDateTime } from 'utils/dates';
import { useState, useEffect, useContext } from 'react';
import UserContext from 'UserContext';
import axios from 'axios';
import AllIdeasTab from './ListingsTabs/AllIdeasTab';
import ActionableIdeasTab from './ListingsTabs/ActionableIdeasTab';
import SummaryTab from './SummaryTab/SummaryTab';
import SpamIdeasTab from './ListingsTabs/SpamIdeasTab';
import RespondedIdeasTab from './ListingsTabs/RespondedIdeasTab';

const ALL_PRODUCT_CATEGORIES = 'ALL_CATEGORIES';

const MAX_PRODUCTS_COUNT = 5;

const areArraysEqual = (array1, array2) => array1.sort().join('|') === array2.sort().join('|');

const commaSeparated = (acc, elem) => (acc === null ? [elem] : [...acc, ',', elem]);

const productToURLRef = (product) => {
  if (product.category_id !== ALL_PRODUCT_CATEGORIES) {
    return `${product.reference}.${product.category_id}`;
  }
  return product.reference;
};

const isProductListMatch = (productRefs, productList) => {
  const productListRefs = productList.products.map(productToURLRef);
  return areArraysEqual(productRefs, productListRefs);
};

const IdeasPage = () => {
  const [products, setProducts] = useState([]);
  const [noAccessProducts, setNoAccessProducts] = useState([]);
  const [invalidProductRefs, setInvalidProductRefs] = useState([]);
  const [dataCollectedAt, setDataDate] = useState(null);
  const [currentProductList, setCurrentProductList] = useState(null);
  const [showSpamTab, setShowSpamTab] = useState(false);
  const [showRespondedTab, setShowRespondedTab] = useState(false);
  const [productRefs, setProductRefs] = useQueryParamArray('product_refs');
  const [showAllProducts, setShowAllProducts] = useState(false);

  const {productLists, selectedProductList, selectProductList} = useContext(UserContext);

  const tabs = [
    {text: 'Summary', link: '/ideas/summary'},
    {text: 'Actionable Ideas', link: '/ideas/actionable'},
    {text: 'All Ideas', link: '/ideas/all'},
  ];

  if (showSpamTab) {
    tabs.push({text: 'Spam Ideas', link: '/ideas/spam'});
  }

  if (showRespondedTab) {
    tabs.push({text: 'Responded Ideas', link: '/ideas/responded'});
  }

  const selectedProductListHasProducts = selectedProductList && selectedProductList.products.length !== 0;
  const userHasOtherProductLists = productLists.length > 1;

  useEffect(() => {
    const fetchDate = async () => {
      const { data } = await axios.get('/api/idea_data_date');
      const dataDate = formatDateTime(`${data.data_collected_at}Z`);
      setDataDate(`Data collected: ${dataDate}`);
    };

    fetchDate();
  }, []);

  // Check if this is a custom product list defined in the URL, or matches the user's selected product list
  useEffect(() => {
    if (!productLists) return;
    const productRefsArray = productRefs || [];
    const productListMatch = productLists.find((productList) => isProductListMatch(productRefsArray, productList));

    if (productListMatch) {
      // This is a product list that the user has defined
      setCurrentProductList(productListMatch);
    } else {
      // This is a custom product list that doesn't match any of the user's defined product lists
      setCurrentProductList(null);
    }
  }, [productRefs, productLists, setCurrentProductList]);

  // If the current product list changes, update the selected product
  useEffect(() => {
    if (currentProductList) {
      selectProductList(currentProductList);
    }
  }, [currentProductList, selectProductList]);

  // If the current product list changes, update the product refs
  useEffect(() => {
    if (currentProductList) {
      const listProductRefs = currentProductList.products.map(productToURLRef);

      setProductRefs(listProductRefs);
    }
  }, [currentProductList, setProductRefs]);

  // If there are no products and no current product list, set to the logged in user's selected product list
  useEffect(() => {
    if (!productRefs && !currentProductList) {
      setCurrentProductList(selectedProductList);
    }
  }, [productRefs, currentProductList, selectedProductList, setCurrentProductList]);

  // If the list of product refs change, fetch the list of products
  useEffect(() => {
    if (!productRefs) return;
    setInvalidProductRefs([]);

    const params = { product_refs: productRefs.join(',') };

    const fetchProducts = async () => {
      const { data: allProducts } = await axios.get('/api/products', { params });
      allProducts.sort((a, b) => a.name.localeCompare(b.name) || a.category_name.localeCompare(b.category_name));

      // Check access to products
      const productsWithAccess = allProducts.filter((p) => p.has_access);
      const productsWithoutAccess = allProducts.filter((p) => !p.has_access);

      setProducts(productsWithAccess);
      setNoAccessProducts(productsWithoutAccess);

      // Check for products which were not found
      // eslint-disable-next-line arrow-body-style
      const notFoundProductRefs = productRefs.filter((ref) => {
        return !allProducts.find((p) => (p.category_id === ALL_PRODUCT_CATEGORIES ? (ref === p.reference) : (ref === `${p.reference}.${p.category_id}`)));
      });
      setInvalidProductRefs(notFoundProductRefs);

      const productsWithSpam = allProducts.filter((p) => p.spam_ideas_count > 0);
      setShowSpamTab(productsWithSpam.length > 0);

      params.actionable_type = 'response';

      const { data: ideasList } = await axios.get('/api/ideas', { params });
      if (ideasList.length > 0) {
        setShowRespondedTab(true);
      } else {
        setShowRespondedTab(false);
      }
    };
    fetchProducts();
  }, [productRefs]);

  let fullPageContent;

  if (currentProductList && !selectedProductListHasProducts) {
    if (userHasOtherProductLists) {
      fullPageContent = (
        <div className="card-body">
          <h4>The selected product list does not contain any products</h4>
          <p>Please go to the <Link to="/settings">settings</Link> page to choose your products.</p>
        </div>
      );
    } else {
      fullPageContent = (
        <div className="card-body">
          <h4>First time here?</h4>
          <p>Please go to the <Link to="/settings">settings</Link> page to choose your products.</p>
        </div>
      );
    }
  } else if (productRefs && productRefs.length === invalidProductRefs.length) {
    fullPageContent = (
      <div className="card-body">
        <h4>There are no valid selected products.</h4>
        <p>Please go to the <Link to="/settings">settings</Link> page to choose your products.</p>
      </div>
    );
  } else if (productRefs && productRefs.length === noAccessProducts.length) {
    fullPageContent = (
      <div className="card-body">
        <h4>You do not have permission to view any of the selected products.</h4>
        <p>Please go to the <Link to="/settings">settings</Link> page to choose your products.</p>
      </div>
    );
  }

  let content;

  if (fullPageContent) {
    content = (
      <div className="bx--row">
        <div className="bx--col">
          <div className="card">
            {fullPageContent}
          </div>
        </div>
      </div>
    );
  } else {
    content = (
      <Switch>
        <Route path="/ideas/summary">
          <SummaryTab />
        </Route>
        <Route path="/ideas/all">
          <AllIdeasTab />
        </Route>
        <Route path="/ideas/actionable">
          <ActionableIdeasTab />
        </Route>
        <Route path="/ideas/responded">
          <RespondedIdeasTab />
        </Route>
        <Route path="/ideas/spam">
          <SpamIdeasTab />
        </Route>
      </Switch>
    );
  }

  const groupedProducts = [];

  products.forEach((product) => {
    let foundProduct = groupedProducts.find((p) => p.id === product.id);

    if (!foundProduct) {
      foundProduct = {
        id: product.id,
        name: product.name,
        reference: product.reference,
        categories: [],
      };
      groupedProducts.push(foundProduct);
    }

    if (product.category_id !== ALL_PRODUCT_CATEGORIES) {
      foundProduct.categories.push(product.category_name);
    }
  });

  return (
    <div className="bx--grid bx--grid--full-width ideas-page">
      <div className="bx--row">
        <div className="bx--col header">
          <h2>Ideas</h2>
          <h3>
            <strong>Product List:</strong>
            <Dropdown
              id="product-list-dropdown"
              className="product-list-dropdown"
              type="inline"
              titleText=""
              label={productLists ? 'Ad Hoc Product List' : 'Loading...'}
              items={productLists}
              itemToString={(item) => item.name}
              selectedItem={currentProductList}
              size="sm"
              onChange={({selectedItem}) => setCurrentProductList(selectedItem)}
            />
          </h3>
          {products.length > 0 && (
            <h3 className="selected-products">
              <strong>Selected Products:</strong>
              {(groupedProducts.length <= MAX_PRODUCTS_COUNT) ? (
                groupedProducts.map((product) => (
                  <span key={product.id} title={`Product Reference: ${product.reference}`}>
                    {product.name}
                    {product.categories.length > 0 && (
                      ` (${product.categories.join(', ')})`
                    )}
                  </span>
                )).reduce(commaSeparated, null)
              ) : (
                <>
                  {groupedProducts.length} <Button size="sm" kind="ghost" onClick={() => setShowAllProducts(true)}>Show products list</Button>
                  {ReactDOM.createPortal(
                    <ComposedModal className="selected-products-modal" size="sm" open={showAllProducts} onClose={() => setShowAllProducts(false)}>
                      <ModalHeader><h4>Showing {groupedProducts.length} Selected Products</h4></ModalHeader>
                      <ModalBody className="selected-products-modal-body">
                        <ul>
                          {groupedProducts.map((product) => (
                            <li key={product.id} title={`Product Reference: ${product.reference}`}>
                              {product.name}
                              {product.categories.length > 0 && (
                                ` (${product.categories.join(', ')})`
                              )}
                            </li>
                          ))}
                        </ul>
                      </ModalBody>
                    </ComposedModal>,
                    document.body,
                  )}
                </>
              )}
            </h3>
          )}
          {invalidProductRefs.length > 0 && (
            <h3 className="error-message">
              Could not find products: {invalidProductRefs.join(', ')}
            </h3>
          )}
          {noAccessProducts.length > 0 && (
            <h3 className="error-message">
              No access to: {noAccessProducts.map((product) => (
                <span key={product.id} title={`Product Reference: ${product.reference}`}>{product.name}</span>
            )).reduce(commaSeparated, null)}
            </h3>
          )}
          <PageTabs tabs={tabs} />
        </div>
      </div>
      <div className="bx--row">
        <div className="bx--col dataDate">{dataCollectedAt}</div>
      </div>
      {content}
    </div>
  );
};

export default IdeasPage;

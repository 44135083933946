import IdeasListing from './IdeasListing';

const AllIdeasTab = () => (
  <div className="bx--row">
    <div className="bx--col">
      <div className="card">
        <div className="card-body">
          <IdeasListing />
        </div>
      </div>
    </div>
  </div>
);

export default AllIdeasTab;

import PropTypes from 'prop-types';
import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableContainer,
  TableToolbar,
  TableToolbarContent,
  TableToolbarSearch,
  TableCell,
  TableSelectAll,
  TableSelectRow,
  TableBatchActions,
  TableBatchAction,
  Pagination,
  MultiSelect,
  Dropdown,
  TooltipDefinition,
  Checkbox,
  Button,
} from 'carbon-components-react';
import { useState, useEffect, useMemo, useCallback, useContext } from 'react';
import ReactDOM from 'react-dom';
import debounce from 'lodash.debounce';
import axios from 'axios';
import { useQueryParamArray, useQueryParam, useQueryParamBool } from 'hooks/useQueryParam';
import StatusPill from 'components/StatusPill/StatusPill';
import { formatDate } from 'utils/dates';
import UserContext from 'UserContext';
import { Edit16, ErrorOutline16, Information16, Download16 } from '@carbon/icons-react';
import SimilarIdeasModal from './SimilarIdeasModal';
import BulkUpdateModal from './BulkUpdateModal';
import MarkAsSpamModal from './MarkAsSpamModal';

const tableHeaders = [
  {
    key: 'idea_reference',
    header: 'Idea Reference',
  },
  {
    key: 'name',
    header: 'Idea',
  },
  {
    key: 'product',
    header: 'Product',
  },
  {
    key: 'category_list',
    header: 'Category',
  },
  {
    key: 'raised_at',
    header: 'Date Raised',
  },
  {
    key: 'last_updated_at',
    header: 'Last Updated',
  },
  {
    key: 'sla_info',
    header: 'SLA Info',
  },
  {
    key: 'company',
    header: 'Company',
  },
  {
    key: 'customer',
    header_text: 'Inferred Customer',
    header: (
      <span
        className="customer-header bx--tooltip__trigger bx--tooltip--icon__bottom"
        aria-label="This is the Global Buyer Group of the customer, inferred from the email domain of the idea raiser"
      >
        Inferred Customer <Information16 />
      </span>
    ),
  },
  {
    key: 'assignee',
    header: 'Assignee',
  },
  {
    key: 'status',
    header: 'Status',
  },
  {
    key: 'votes',
    header: 'Votes',
  },
  {
    key: 'similar_idea_count',
    header: 'Similar Ideas',
  },
];

const SLA_INFO_FILTER_ITEMS = [
  {label: 'All SLA states', key: null},
  {label: 'Overdue', key: 'overdue'},
  {label: 'Submitted overdue', key: 'overdue_submitted'},
  {label: 'Submitted coming overdue', key: 'coming_overdue_submitted'},
  {label: 'Under review overdue', key: 'overdue_under_review'},
  {label: 'Under review coming overdue', key: 'coming_overdue_under_review'},
];

const ASSIGNEE_FILTER_ITEMS = [
  {label: 'All Assignees', key: null},
  {label: 'No Assignee', key: 'none'},
];

const CATEGORY_FILTER_ITEMS = [
  {label: 'All Categories', key: null},
];

const INFERRED_CUSTOMER_FILTER_ITEMS = [
  {label: 'All Inferred Customers', key: null},
];

const COMPANY_FILTER_ITEMS = [
  {label: 'All Companies', key: null},
  {label: '(Not Defined)', key: 'not_defined'},
];

const CONTACT_DOMAIN_FILTER_ITEMS = [
  {label: 'All Contact Domains', key: null},
];

function getVoterTooltipContent(voteCount, voters) {
  if (voters.length === 0) {
    const voteWord = (voteCount === 1) ? 'vote' : 'votes';
    return `${voteCount} unknown ${voteWord}`;
  }

  let domainVotes = [];

  voters.forEach((userEmail) => {
    let domain = userEmail.split('@')[1];
    if (domain.includes('.ibm.com')) {
      domain = 'ibm.com';
    }

    let domainVotesObj = domainVotes.find((d) => d.domain === domain);

    if (!domainVotesObj) {
      domainVotesObj = {domain, votes: 0};
      domainVotes.push(domainVotesObj);
    }

    domainVotesObj.votes += 1;
  });

  // Show at most 10 domains, ordered by vote count descending
  domainVotes.sort((a, b) => b.votes - a.votes || a.domain.localeCompare(b.domain));
  domainVotes = domainVotes.slice(0, 10);

  const ideaVotesCounts = domainVotes.map((d) => {
    const voteWord = (d.votes === 1) ? 'vote' : 'votes';
    return (
      <span key={d.domain}>
        {d.votes} {voteWord} from {d.domain} <br />
      </span>
    );
  });

  const visibleVotesCount = domainVotes.reduce((acc, d) => acc + d.votes, 0);
  const notVisibleVotesCount = voteCount - visibleVotesCount;

  if (notVisibleVotesCount > 0) {
    const voteWord = (notVisibleVotesCount === 1) ? 'vote' : 'votes';
    ideaVotesCounts.push(
      <span key="other">
        + {notVisibleVotesCount} more {voteWord}
      </span>,
    );
  }

  return ideaVotesCounts;
}

const IdeasListing = ({actionableType}) => {
  const {listingColumns, updateListingColumns} = useContext(UserContext);

  const [ideas, setIdeas] = useState([]);
  const [ideaRows, setIdeaRows] = useState([]);
  const [totalIdeas, setTotalIdeas] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortHeaderKey, setSortHeaderKey] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [assigneeFilterItems, setAssigneeFilterItems] = useState(ASSIGNEE_FILTER_ITEMS);
  const [selectedAssigneeFilterItem, setSelectedAssigneeFilterItem] = useState(ASSIGNEE_FILTER_ITEMS[0]);
  const [categoryFilterItems, setCategoryFilterItems] = useState(CATEGORY_FILTER_ITEMS);
  const [selectedCategoryFilterItem, setSelectedCategoryFilterItems] = useState(CATEGORY_FILTER_ITEMS[0]);
  const [inferredCustomerFilterItems, setInferredCustomerFilterItems] = useState(INFERRED_CUSTOMER_FILTER_ITEMS);
  const [selectedInferredCustomerFilterItem, setSelectedInferredCustomerFilterItem] = useState(INFERRED_CUSTOMER_FILTER_ITEMS[0]);
  const [companyFilterItems, setCompanyFilterItems] = useState(COMPANY_FILTER_ITEMS);
  const [selectedCompanyFilterItem, setSelectedCompanyFilterItem] = useState(COMPANY_FILTER_ITEMS[0]);
  const [contactDomainFilterItems, setContactDomainFilterItems] = useState(CONTACT_DOMAIN_FILTER_ITEMS);
  const [selectedContactDomainFilterItem, setSelectedContactDomainFilterItem] = useState(CONTACT_DOMAIN_FILTER_ITEMS[0]);

  const [statusFilterItems, setStatusFilterItems] = useState([]);
  const [selectedStatusFilterItems, setSelectedStatusFilterItems] = useState([]);

  const [bulkUpdateModalOpen, setbulkUpdateModalOpen] = useState(false);
  const [markAsSpamModalOpen, setMarkAsSpamModalOpen] = useState(false);
  const [selectedIdeas, setSelectedIdeas] = useState([]);

  const [productRefs] = useQueryParamArray('product_refs');
  const [statusesFilter, setStatusesFilter] = useQueryParamArray('statuses');
  const [slaInfoFilter, setSlaInfoFilter] = useQueryParam('sla_info');
  const [inferredCustomerFilter, setInferredCustomerFilter] = useQueryParam('inferred_customer');
  const [companyFilter, setCompanyFilter] = useQueryParam('company');
  const [contactDomainFilter, setContactDomainFilter] = useQueryParam('contact_domain');
  const [assigneeFilter, setAssigneeFilter] = useQueryParam('assignee');
  const [categoryFilter, setCategoryFilter] = useQueryParam('category');
  const [includeClosed, setIncludeClosed] = useQueryParamBool('include_closed');

  let selectedSlaInfoFilterItem = SLA_INFO_FILTER_ITEMS.find((item) => item.key === slaInfoFilter);

  if (!selectedSlaInfoFilterItem) {
    [selectedSlaInfoFilterItem] = SLA_INFO_FILTER_ITEMS;
  }

  useEffect(() => {
    let newSelectedInferredCustomerFilterItem = inferredCustomerFilterItems.find((item) => item.key === inferredCustomerFilter);

    if (!newSelectedInferredCustomerFilterItem) {
      [newSelectedInferredCustomerFilterItem] = inferredCustomerFilterItems;
    }
    setSelectedInferredCustomerFilterItem(newSelectedInferredCustomerFilterItem);
  }, [inferredCustomerFilter, inferredCustomerFilterItems]);

  useEffect(() => {
    let newSelectedCompanyFilterItem = companyFilterItems.find((item) => item.key === companyFilter);

    if (!newSelectedCompanyFilterItem) {
      [newSelectedCompanyFilterItem] = companyFilterItems;
    }
    setSelectedCompanyFilterItem(newSelectedCompanyFilterItem);
  }, [companyFilter, companyFilterItems]);

  useEffect(() => {
    let newSelectedContactDomainFilterItem = contactDomainFilterItems.find((item) => item.key === contactDomainFilter);

    if (!newSelectedContactDomainFilterItem) {
      [newSelectedContactDomainFilterItem] = contactDomainFilterItems;
    }
    setSelectedContactDomainFilterItem(newSelectedContactDomainFilterItem);
  }, [contactDomainFilter, contactDomainFilterItems]);

  useEffect(() => {
    let newSelectedAssigneeFilterItem = assigneeFilterItems.find((item) => item.key === assigneeFilter);

    if (!newSelectedAssigneeFilterItem) {
      [newSelectedAssigneeFilterItem] = assigneeFilterItems;
    }
    setSelectedAssigneeFilterItem(newSelectedAssigneeFilterItem);
  }, [assigneeFilter, assigneeFilterItems]);

  useEffect(() => {
    let newSelectedCategoryFilterItem = categoryFilterItems.find((item) => item.key === categoryFilter);

    if (!newSelectedCategoryFilterItem) {
      [newSelectedCategoryFilterItem] = categoryFilterItems;
    }
    setSelectedCategoryFilterItems(newSelectedCategoryFilterItem);
  }, [categoryFilter, categoryFilterItems]);

  useEffect(() => {
    let newSelectedStatusFilterItems = [];
    if (statusesFilter) {
      newSelectedStatusFilterItems = statusFilterItems.filter((statusFilterItem) => statusesFilter.includes(statusFilterItem.key));
    }
    setSelectedStatusFilterItems(newSelectedStatusFilterItems);
  }, [statusesFilter, statusFilterItems]);

  const queryParams = useMemo(() => {
    const params = {
      product_refs: productRefs.join(','),
    };

    if (actionableType) {
      params.actionable_type = actionableType;
    }

    if (statusesFilter) {
      params.statuses = statusesFilter.join(',');
    }

    if (includeClosed) {
      params.include_closed = includeClosed;
    }

    if (slaInfoFilter) {
      params.sla_info = slaInfoFilter;
    }

    if (categoryFilter) {
      params.category = categoryFilter;
    }

    if (inferredCustomerFilter) {
      params.inferred_customer = inferredCustomerFilter;
    }

    if (companyFilter) {
      params.company = companyFilter;
    }

    if (contactDomainFilter) {
      params.contact_domain = contactDomainFilter;
    }

    if (assigneeFilter) {
      params.assignee = assigneeFilter;
    }

    if (sortHeaderKey && sortDirection) {
      params.sort = sortHeaderKey;
      params.sort_direction = sortDirection;
    }

    if (searchQuery !== '') {
      params.query = searchQuery;
    }

    return params;
  }, [actionableType, assigneeFilter, categoryFilter, companyFilter, contactDomainFilter, includeClosed, inferredCustomerFilter, productRefs, searchQuery, slaInfoFilter, sortDirection, sortHeaderKey, statusesFilter]);

  const fetchIdeas = useCallback(async () => {
    if (!productRefs) return;
    const res = await axios.get('/api/ideas', { params: {...queryParams, page: currentPage, per_page: pageSize } });

    const resIdeas = res.data;
    const resTotalIdeas = parseInt(res.headers['pagination-total-items'], 10);

    const newIdeaRows = resIdeas.map((idea, index) => {
      let slaInfo;
      if (idea.sla_overdue_state === 'overdue') {
        slaInfo = (
          <span className="overdue">
            Overdue&nbsp;by {-idea.sla_due_in_days}&nbsp;days
          </span>
        );
      } else if (idea.sla_overdue_state === 'coming_due') {
        slaInfo = (
          <span className="coming-overdue">
            Overdue&nbsp;in {idea.sla_due_in_days}&nbsp;days
          </span>
        );
      } else if (idea.sla_due_in_days) {
        slaInfo = (
          <span className="actionable">
            Overdue&nbsp;in {idea.sla_due_in_days}&nbsp;days
          </span>
        );
      }

      const categoriesString = (idea.category_list || []).join(', ');

      const tooltipDirection = (index < resIdeas.length - 5) ? 'bottom' : 'top';

      let inferredCustomerName;

      if (idea.inferred_customer_name === 'PROTECTED') {
        inferredCustomerName = (
          <span>
            <TooltipDefinition
              direction="bottom"
              tooltipText="Customer data for this idea is sensitive and redacted"
            >
              {idea.inferred_customer_name}
            </TooltipDefinition>
          </span>
        );
      } else {
        inferredCustomerName = idea.inferred_customer_name;
      }

      let assigneeInfo = idea.assignee;
      const dagger = (idea.assignee_left_ibm_flag || (idea.assignee.includes('deleted-user'))) && (<sup>&#8224;</sup>);

      if (idea.assignee === idea.default_assignee_email && idea.default_assignee_email !== '') {
        assigneeInfo = (
          <span className="default-assignee">
            <TooltipDefinition
              direction={tooltipDirection}
              tooltipText="This is the default assignee for this product"
            >
              {idea.assignee}
              *
              {dagger}
            </TooltipDefinition>
          </span>
        );
      } else {
        assigneeInfo = (
          <>
            {idea.assignee}
            {dagger}
          </>
        );
      }

      const statusPill = <StatusPill name={idea.status} color={idea.status_color} />;

      let similarIdeasCount = idea.similar_idea_count;

      if (idea.similar_idea_count !== 0) {
        similarIdeasCount = <>{idea.similar_idea_count} <SimilarIdeasModal idea={idea} onClose={fetchIdeas} /></>;
      }

      let ideaVotes;

      if (idea.votes === 0) {
        ideaVotes = 0;
      } else {
        const tooltipContent = getVoterTooltipContent(idea.votes, idea.idea_voters);

        ideaVotes = (
          <span className="tooltip-idea-voters">
            <TooltipDefinition
              direction={tooltipDirection}
              align="end"
              tooltipText={tooltipContent}
            >
              {idea.votes}
            </TooltipDefinition>
          </span>
        );
      }

      return {
        id: idea.id,
        idea_reference: <a href={idea.url}>{idea.idea_reference}</a>,
        product: idea.product,
        category_list: categoriesString,
        name: idea.name,
        raised_at: formatDate(idea.raised_at),
        last_updated_at: formatDate(idea.last_updated_at),
        sla_info: slaInfo,
        assignee: assigneeInfo,
        customer: inferredCustomerName,
        company: idea.company_name,
        status: statusPill,
        votes: ideaVotes,
        similar_idea_count: similarIdeasCount,
      };
    });

    setIdeas(resIdeas);
    setIdeaRows(newIdeaRows);
    setTotalIdeas(resTotalIdeas);
  }, [productRefs, queryParams, currentPage, pageSize]);

  useEffect(() => {
    fetchIdeas();
  }, [fetchIdeas]);

  useEffect(() => {
    if (!productRefs) return;

    const fetchFilters = async () => {
      const params = { product_refs: productRefs.join(',') };

      if (actionableType) {
        params.actionable_type = actionableType;
      }

      const { data } = await axios.get('/api/idea_filters', { params });

      const inferredCustomers = data.inferred_customers.map((c) => ({label: c, key: c}));
      const newInferredCustomerFilterItems = [...INFERRED_CUSTOMER_FILTER_ITEMS, ...inferredCustomers];
      setInferredCustomerFilterItems(newInferredCustomerFilterItems);

      const companies = data.companies.map((c) => ({label: c, key: c}));
      const newCompanyFilterItems = [...COMPANY_FILTER_ITEMS, ...companies];
      setCompanyFilterItems(newCompanyFilterItems);

      const contactDomains = data.contact_domains.map((c) => ({label: c, key: c}));
      const newContactDomainFilterItems = [...CONTACT_DOMAIN_FILTER_ITEMS, ...contactDomains];
      setContactDomainFilterItems(newContactDomainFilterItems);

      const assigneeEmails = data.assignees.map((a) => ({label: a, key: a}));
      const newAssigneeFilterItems = [...ASSIGNEE_FILTER_ITEMS, ...assigneeEmails];
      setAssigneeFilterItems(newAssigneeFilterItems);

      const categories = data.categories.map((c) => ({label: c, key: c}));
      const newCategoryFilterItems = [...CATEGORY_FILTER_ITEMS, ...categories];
      setCategoryFilterItems(newCategoryFilterItems);

      const newStatusFilterItems = data.statuses.map((a) => ({label: a, key: a}));
      setStatusFilterItems(newStatusFilterItems);
    };

    fetchFilters();
  }, [productRefs, actionableType]);

  const getIdeaClass = (ideaId) => {
    const idea = ideas.find(({ id }) => id === ideaId);
    if (!idea) { return ''; }
    if (idea.sla_overdue_state === 'overdue') {
      return 'overdue-row';
    }
    if (idea.sla_overdue_state === 'coming_due') {
      return 'coming-overdue-row';
    }
    return '';
  };

  const onHeaderClick = (e, options) => {
    if (options.sortDirection === 'NONE') {
      setSortHeaderKey(null);
      setSortDirection(null);
    } else {
      setSortHeaderKey(options.sortHeaderKey);
      setSortDirection(options.sortDirection.toLowerCase());
    }
    setCurrentPage(1);
  };

  const onSearchChange = (newQuery) => {
    setSearchQuery(newQuery);
  };

  const onSearchChangeDebounced = useMemo(() => debounce(onSearchChange, 500), []);

  const onStatusChange = (selectedItems) => {
    const statuses = selectedItems.map((item) => item.label);
    if (statuses.length === 0) {
      setStatusesFilter(null);
    } else {
      setStatusesFilter(statuses);
    }
  };

  const onSLAInfoChange = (selectedItem) => {
    setSlaInfoFilter(selectedItem.key);
  };

  const onInferredCustomerChange = (selectedItem) => {
    setInferredCustomerFilter(selectedItem.key);
  };

  const onContactDomainChange = (selectedItem) => {
    setContactDomainFilter(selectedItem.key);
  };

  const onCompanyChange = (selectedItem) => {
    setCompanyFilter(selectedItem.key);
  };

  const onAssigneeChange = (selectedItem) => {
    setAssigneeFilter(selectedItem.key);
  };

  const onCategoryChange = (selectedItem) => {
    setCategoryFilter(selectedItem.key);
  };

  const onIncludeClosedChange = (value) => {
    setIncludeClosed(value);
  };

  const onBulkUpdateClick = (selectedItems) => {
    const selectedIdeaIds = selectedItems.map((item) => item.id);
    const filteredIdeas = ideas.filter((idea) => selectedIdeaIds.includes(idea.id));
    setSelectedIdeas(filteredIdeas);
    setbulkUpdateModalOpen(true);
  };

  const onMarkAsSpamClick = (selectedItems) => {
    const selectedIdeaIds = selectedItems.map((item) => item.id);
    const filteredIdeas = ideas.filter((idea) => selectedIdeaIds.includes(idea.id));
    setSelectedIdeas(filteredIdeas);
    setMarkAsSpamModalOpen(true);
  };

  // Get visible table headers from listing columns
  const visibleColumns = listingColumns.filter((col) => col.visible);
  const visibleColumnKeys = visibleColumns.map((col) => col.key);
  const visibleTableHeaders = tableHeaders.filter((header) => visibleColumnKeys.includes(header.key));

  const setVisibleColumns = (newTableHeaders) => {
    const visibleTableHeaderKeys = newTableHeaders.map((col) => col.key);

    let newListingColumns;
    if (visibleTableHeaderKeys.length !== 0) {
      newListingColumns = listingColumns.map((col) => ({...col, visible: visibleTableHeaderKeys.includes(col.key)}));
    } else {
      // If no columns visible, set all columns to visible
      newListingColumns = listingColumns.map((col) => ({...col, visible: true}));
    }

    updateListingColumns(newListingColumns);
  };

  const assigneeHasLeftLegend = (
    <span>
      <div className="defaultAssigneeNotice">
        * default assignee
      </div>
      <div className="defaultAssigneeNotice">
        † assignee has left IBM
      </div>
    </span>
  );

  return (
    <div className="ideas-listing">
      {typeof document === 'undefined'
        ? null
        : [
          ReactDOM.createPortal(
            <BulkUpdateModal
              open={bulkUpdateModalOpen}
              onClose={() => { setbulkUpdateModalOpen(false); fetchIdeas(); }}
              ideas={selectedIdeas}
            />,
            document.body,
          ),
          ReactDOM.createPortal(
            <MarkAsSpamModal
              open={markAsSpamModalOpen}
              onClose={() => { setMarkAsSpamModalOpen(false); fetchIdeas(); }}
              ideas={selectedIdeas}
            />,
            document.body,
          ),
        ]}
      <div className="filters">
        <MultiSelect
          key={selectedStatusFilterItems.map((i) => i.key).join(',')}
          label="Statuses"
          titleText="Filter by Status"
          id="filter-by-statuses"
          className="filter"
          items={statusFilterItems}
          initialSelectedItems={selectedStatusFilterItems}
          onChange={({selectedItems}) => onStatusChange(selectedItems)}
        />
        <Dropdown
          label="Filter by SLA Info"
          titleText="Filter by SLA Info"
          id="filter-by-sla-info"
          className="filter"
          items={SLA_INFO_FILTER_ITEMS}
          initialSelectedItem={selectedSlaInfoFilterItem}
          onChange={({selectedItem}) => onSLAInfoChange(selectedItem)}
        />
        <Dropdown
          label="Filter by Category"
          titleText="Filter by Category"
          id="filter-by-category"
          className="filter"
          items={categoryFilterItems}
          selectedItem={selectedCategoryFilterItem}
          onChange={({selectedItem}) => onCategoryChange(selectedItem)}
        />
        <Dropdown
          label="Filter by Company"
          titleText="Filter by Company"
          id="filter-by-company"
          className="filter"
          items={companyFilterItems}
          selectedItem={selectedCompanyFilterItem}
          onChange={({selectedItem}) => onCompanyChange(selectedItem)}
        />
        <Dropdown
          label="Filter by Inferred Customer"
          titleText="Filter by Inferred Customer"
          id="filter-by-customer"
          className="filter"
          items={inferredCustomerFilterItems}
          selectedItem={selectedInferredCustomerFilterItem}
          onChange={({selectedItem}) => onInferredCustomerChange(selectedItem)}
        />
        <Dropdown
          label="Filter by Contact Domain"
          titleText="Filter by Contact Domain"
          id="filter-by-contact-domain"
          className="filter"
          items={contactDomainFilterItems}
          selectedItem={selectedContactDomainFilterItem}
          onChange={({selectedItem}) => onContactDomainChange(selectedItem)}
        />
        <Dropdown
          label="Filter by Assignee"
          titleText="Filter by Assignee"
          id="filter-by-assignee"
          className="filter"
          items={assigneeFilterItems}
          selectedItem={selectedAssigneeFilterItem}
          onChange={({selectedItem}) => onAssigneeChange(selectedItem)}
        />
        {actionableType !== 'sla' ? (
          <Checkbox
            labelText="Include Closed Ideas"
            id="filter-include-closed"
            className="filter filter-checkbox"
            checked={includeClosed}
            onChange={(value) => onIncludeClosedChange(value)}
          />
        ) : ''}
        <MultiSelect
          id="show-hide-columns"
          className="filter"
          titleText="Show/Hide Columns"
          label="Visible Columns"
          items={tableHeaders}
          itemToString={(item) => item.header_text || item.header}
          compareItems={() => 0}
          sortItems={(items) => items}
          selectedItems={visibleTableHeaders}
          onChange={({selectedItems}) => setVisibleColumns(selectedItems)}
        />
      </div>
      <DataTable
        rows={ideaRows}
        headers={visibleTableHeaders}
        sortRow={() => null}
      >
        {({ rows, headers, getTableProps, getHeaderProps, getRowProps, getSelectionProps, getBatchActionProps, selectedRows }) => (
          <TableContainer>
            <TableToolbar>
              <TableBatchActions {...getBatchActionProps()}>
                <TableBatchAction
                  tabIndex={getBatchActionProps().shouldShowBatchActions ? 0 : -1}
                  renderIcon={Edit16}
                  onClick={() => { getBatchActionProps().onCancel(); onBulkUpdateClick(selectedRows); }}
                >
                  Bulk Update
                </TableBatchAction>
                <TableBatchAction
                  tabIndex={getBatchActionProps().shouldShowBatchActions ? 0 : -1}
                  renderIcon={ErrorOutline16}
                  onClick={() => { getBatchActionProps().onCancel(); onMarkAsSpamClick(selectedRows); }}
                >
                  Mark as Spam
                </TableBatchAction>
              </TableBatchActions>
              <TableToolbarContent>
                <TableToolbarSearch
                  persistent
                  placeholder="Filter ideas"
                  onChange={(e) => onSearchChangeDebounced(e.target.value)}
                />
                <Button
                  renderIcon={Download16}
                  disabled={totalIdeas === 0}
                  size="sm"
                  href={`/api/ideas.csv?${new URLSearchParams(queryParams)}`}
                >
                  Download {totalIdeas} {totalIdeas === 1 ? 'idea' : 'ideas'} as CSV
                </Button>
              </TableToolbarContent>
            </TableToolbar>
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  <TableSelectAll {...getSelectionProps()} />
                  {headers.map((header) => (
                    <TableHeader {...getHeaderProps({ header, onClick: onHeaderClick, isSortable: true })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow {...getRowProps({ row, className: getIdeaClass(row.id) })} className={getIdeaClass(row.id)}>
                    <TableSelectRow {...getSelectionProps({ row })} />
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id} className={`col-${cell.info.header}`}>{cell.value}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

      </DataTable>
      <Pagination
        pageSizes={[10, 20, 50, 100]}
        page={currentPage}
        pageSize={pageSize}
        totalItems={totalIdeas}
        onChange={(pagination) => {
          if (pagination.pageSize !== pageSize) {
            setPageSize(pagination.pageSize);
          }
          setCurrentPage(pagination.page);
        }}
      />
      <div className="defaultAssigneeNotice">
        {assigneeHasLeftLegend}
      </div>
    </div>
  );
};

IdeasListing.propTypes = {
  actionableType: PropTypes.string,
};

IdeasListing.defaultProps = {
  actionableType: null,
};

export default IdeasListing;

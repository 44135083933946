import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ComposedModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InlineLoading,
  Button,
  Checkbox,
} from 'carbon-components-react';
import axios from 'axios';

const MarkAsSpamModal = ({ open, onClose, ideas }) => {
  const [shouldDisablePortalUser, setShouldDisablePortalUser] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const raisedByEmails = ideas.map((idea) => idea.raised_by_email);
  const uniqueRaisedByEmails = [...new Set(raisedByEmails)];

  const onSubmit = async () => {
    setErrorMessage(null);
    setIsSubmitting(true);

    const bulkUpdate = {
      ids: ideas.map((idea) => idea.id),
      is_spam: true,
      disable_portal_user: shouldDisablePortalUser,
    };

    try {
      await axios.patch('/api/ideas', bulkUpdate);
      onClose();
    } catch (err) {
      if ('detail' in err.response.data) {
        setErrorMessage(err.response.data.detail);
      } else {
        setErrorMessage('Failed to update Aha! ideas');
      }
    }

    setIsSubmitting(false);
  };

  return (
    <ComposedModal
      className="mark-as-spam-modal"
      size="sm"
      open={open}
      onClose={onClose}
      preventCloseOnClickOutside
    >
      <ModalHeader>
        <h4>Are you sure you want to mark {ideas.length === 1 ? 'this idea' : `these ${ideas.length} ideas`} as spam?</h4>
      </ModalHeader>
      <ModalBody>
        <ul>
          {ideas.map((idea) => <li key={idea.id}>{idea.name}</li>)}
        </ul>
        <Checkbox
          labelText={`Also disable the portal ${uniqueRaisedByEmails.length === 1 ? 'user' : 'users'}: ${uniqueRaisedByEmails.join(', ')}`}
          id="disable-portal-user"
          className="disable-portal-user"
          checked={shouldDisablePortalUser}
          onChange={(value) => setShouldDisablePortalUser(value)}
        />
        {errorMessage && (
          <div className="error-message">{errorMessage}</div>
        )}
      </ModalBody>
      <ModalFooter
        onRequestSubmit={onSubmit}
      >
        <Button kind="secondary" disabled={isSubmitting} onClick={onClose}>Cancel</Button>
        <div className="submit-container">
          { isSubmitting ? (
            <InlineLoading
              className="submit-loading"
              description={ideas.length === 1 ? 'Marking idea as spam...' : `Marking ${ideas.length} ideas as spam...`}
            />
          ) : (
            <Button
              kind="primary"
              onClick={onSubmit}
            >
              {ideas.length === 1 ? 'Mark idea as spam' : `Mark ${ideas.length} ideas as spam`}
            </Button>
          )}
        </div>
      </ModalFooter>
    </ComposedModal>
  );
};

MarkAsSpamModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  ideas: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default MarkAsSpamModal;
